<template>
  <div class="fill-data-config-container">
    <h3>自定义填充数据</h3>
    <template v-if="suitList.indexOf(chartData.type) > -1">
      <div class="fill-data-warp" >
        <el-form label-position="top">
          <section v-for="(item, index) in fillConfig" :key="index" class="fill-data-item">
            <div class="type-label">
              <span>数据项{{ index + 1 }}</span>
              <el-button type="text" @click="doDeleteDataItem(index)"><i style="color: red" class="iconfont icongis_shanchu" /></el-button>
            </div>
            <el-form-item v-if="needTypeList.indexOf(chartData.type) > -1" label="图表类型(仅用于区分折线与柱状图)">
              <el-select v-model="item.chartType" placeholder="请选择图表类型" style="width: 100%">
                <el-option label="柱状图" value="bar"></el-option>
                <el-option label="折线图" value="line"></el-option>
              </el-select>
            </el-form-item>
            <el-form-item label="数据">
              <el-button type="text" @click.stop="doDealData(item)">共 {{ item.data?.data?.length || 0 }} 条数据</el-button>
            </el-form-item>
          </section>
        </el-form>
        <el-button type="text" v-if="needTypeList.indexOf(chartData.type) > -1 || fillConfig.length < 1" @click.stop="doAddDataItem">
          <i class="iconfont iconbim_jia" />
          新增数据项
        </el-button>
        <el-dialog
          :close-on-click-modal="false"
          title="数据填充"
          :visible.sync="dialogVisible"
        >
          <FillDataModel 
            v-if="dialogVisible"
            :dataSource="currenData"
            @cancle="dialogVisible = false"
            @submit="handleSubmit"
          />
        </el-dialog>
      </div>
      <footer class="fill-data-config-footer">
        <el-button type="primary" @click="onSubmit">
          提交
        </el-button>
      </footer>
    </template>
    <p class="not-support-msg" v-else>
      当前组件不支持填充数据
    </p>
  </div>
</template>
<script>
import FillDataModel from './FillDataModel'
import { mapState } from 'vuex';
export default{
  components: {
    FillDataModel
  },
  data() {
    return {
      fillConfig: [],
      dialogVisible: false,
      currenData: {},
      currentItem: {}
    }
  },
  computed: {
    ...mapState({
			chartData: (state) => state.chart.chartData || null
		}),
    suitList() {
      return ['line', 'bar', 'barLine', 'pie', 'ring']
    },
    needTypeList() {
      return ['barLine']
    }
  },
  methods: {
    doAddDataItem() {
      this.fillConfig.push({
        chartType: '',
        data: {
          col: [],
          data: []
        }
      })
    },
    doDeleteDataItem(index) {
      this.$confirm('删除后不可恢复, 是否继续?', '提示', {
        confirmButtonText: '删除',
        cancelButtonText: '取消',
        type: 'error'
      }).then(() => {
        this.fillConfig.splice(index, 1)
      }).catch(() => {
        // 没有什么需要操作的         
      });
    },
    doDealData(item) {
      this.dialogVisible = true;
      this.currentItem = item;
      this.currenData = item.data;
    },
    handleSubmit(data) {
      this.currentItem.data = data
      this.dialogVisible = false
    },
    onSubmit() {
      this.$store.commit('setChartData', {
				...this.chartData,
				fillConfig: this.fillConfig
			});
    }
  },
  mounted() {
    this.fillConfig = this.chartData?.fillConfig || []
  }
}
</script>
<style lang="less" scoped>
  .fill-data-config-container{
    text-align: left;
    .fill-data-warp{
      .fill-data-item{
        .type-label{
          display: flex;
          justify-content: space-between;
          align-items: center;
          padding-top: 0;
          padding-bottom: 0;
        }
      }
    }
    .fill-data-config-footer{
      margin-top: 5px;
      text-align: center;
    }
    .not-support-msg{
      text-align: center;
    }
  }
</style>
