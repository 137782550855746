<!--
 * @Description: 颜色集合
 * @Author: luocheng
 * @Date: 2022-01-12 14:17:18
 * @LastEditors: luocheng
 * @LastEditTime: 2022-05-23 16:21:02
-->
<template>
	<div class="base-color-range base-com" v-if="bindVal">
		<p class="label">{{ field }}</p>
    <div class="color-range-main">
      <el-color-picker v-model="newColor" @change="pushNewColor"></el-color-picker>
      <div class="color-list">
        <el-tag v-for="(item, index) in bindVal" :key="index"
          :color="item"
          closable
          @close="onRemove(index)"
        >{{ item }}</el-tag>
      </div>
    </div>
	</div>
</template>

<script>
import { ColorPicker, Tag } from 'element-ui';
import baseMixin from './base';

export default {
	name: 'BaseColorRange',
  components: {
    'el-color-picker': ColorPicker,
    'el-tag': Tag
  },
	mixins: [baseMixin],
  data() {
    return {
      newColor: '#000000'
    }
  },
  methods: {
    /**
     * @desc: 新增颜色
     * @param {String} color 选中的颜色
     */
    pushNewColor(color) {
      this.bindVal.push(color);
      this.updateVal();
    },
    /**
     * @desc: 删除颜色
     * @param {Number} index 下标
     */
    onRemove(index) {
      this.bindVal.splice(index, 1);
      this.updateVal();
    }
  }
};
</script>

<style lang="less" scoped>
.base-com{
  display: flex;
  padding-bottom: 10px;
  .label{
    min-width: 80px;
    max-width: 200px;
    line-height: 28px;
    font-size: 14px;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    margin-right: 10px;
  }
  .color-range-main{
    display: flex;
    flex-direction: column;
    .el-color-picker{
      text-align: left;
      margin-bottom: 5px;
    }
    .color-list{
      display: flex;
      justify-content: flex-start;
      flex-wrap: wrap;
      .el-tag{
        color: #fff;
      }
    }
  }
}
</style>