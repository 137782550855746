<!--
 * @Description: 图表渲染
 * @Author: luocheng
 * @Date: 2022-01-07 15:55:46
 * @LastEditors: luocheng
 * @LastEditTime: 2022-06-22 17:19:50
-->
<template>
	<div
		class="chart-box"
		:class="[chartData.id]"
		v-if="chartData"
	>
		<!-- 翻牌器 -->
		<template v-if="chartData.component === 'ChartFlop'">
			<component
				:is="chartData.component"
				:chartData="chartData"
				:height="chartHeight"
				:chartUUID="chartUUID"
			></component>
		</template>
		<!-- 图表 -->
		<component
			:is="chartData.component"
			v-else-if="initEnd"
			:chartData="chartData"
			:height="chartHeight"
			:chartUUID="chartUUID"
		>
		</component>
		<el-empty
			v-else
			description="配置项错误"
		></el-empty>
	</div>
</template>

<script>
import { Empty } from 'element-ui';
import { mapState } from 'vuex';
// 注册组件
const componentsList = require.context(
	'@/chart-components/components',
	true,
	/\.+vue$/
);
const customComponents = {};
componentsList.keys().forEach((fileName) => {
	let name = fileName.replace(/.vue/, '');
	name = name.replace(/\.\//, '');
	if (name.indexOf('/') < 0) {
		// name = name.split('/')[1];
		customComponents[name] = componentsList(fileName).default;
	}
});

export default {
	name: 'ChartBox',
	components: {
		...customComponents,
		'el-empty': Empty
	},
	data() {
		return {
			chartHeight: 300,
			domInitEnd: false,
			configInitEnd: false,
			chartUUID: this.$route.query.pageUUID
		};
	},
	computed: {
		...mapState({
			chartData: (state) => state.chart.chartData,
			canvasStyle: (state) => state.canvasStyle
		}),
		initEnd() {
			// console.log(this.domInitEnd, this.configInitEnd, '--------')
			// return true;
			return this.domInitEnd && this.configInitEnd;
		}
	},
	watch: {
		canvasStyle: {
			handler(newVal) {
				if (!newVal) return false;
				this.resetBoxSize();
			},
			deep: true
		},
		chartData: {
			handler(newV) {
				this.configInitEnd = this.validConfig(newV);
			},
			deep: true,
			immediate: true
		}
	},
	mounted() {
		console.log(this.chartData, '----chartData---')
		this.resetBoxSize();
		window.onresize = () => {
			this.resetBoxSize();
		};
	},
	methods: {
		/**
		 * @desc: 样式resize
		 */
		resetBoxSize() {
			this.$nextTick(() => {
				if (this.chartData?.id) {
					const chartHeight =
						document.querySelector(`.${this.chartData?.id}`)?.offsetHeight ||
						200;
					this.chartHeight = chartHeight - 32;
					this.domInitEnd = true;
				}
			});
		},
		/**
		 * @desc: 验证配置项
		 */
		validConfig(chartData) {
			if (!chartData || typeof chartData !== 'object') return false;
			// 静态配置暂未验证
			const { sourceType, type, configs } = chartData;
			// 自定义和静态暂未验证
			if (sourceType === 'static' || sourceType === 'custom' || sourceType === 'fillData') return true;
			if (!type) {
				// this.$message.error('请选择图表类型!');
				return false;
			}
			if (!configs || !Array.isArray(configs) || !configs.length) {
				// this.$message.error('请至少进行一项配置！');
				return false;
			}
			for (let i = 0; i < configs.length; i++) {
				const { view_uuid, x, y } = configs[i];
				if (!view_uuid) {
					// this.$message.error('请选择统计视图！');
					return false;
				}
				if (!x || !x.field_uuid) {
					// this.$message.error('请配置维度字段!');
					return false;
				}
				if (!y) {
					// this.$message.error('请配置指标字段!');
					return false;
				}
			}
			return true;
		}
	}
};
</script>

<style lang="less" scoped>
.chart-box {
	height: 100%;
	width: 100%;
	box-sizing: border-box;
	padding: 16px;
}
</style>