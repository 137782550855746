<!--
 * @Description: 特殊配置
 * @Author: luocheng
 * @Date: 2022-07-26 15:57:23
 * @LastEditors: luocheng
 * @LastEditTime: 2022-07-27 16:27:02
-->
<template>
  <div class="special-config">
    <!-- 折柱混合 -->
    <template v-if="chartType === 'ChartBarLine'">
      <BarLine></BarLine>
    </template>
    <el-empty description="暂无配置" v-else></el-empty>
  </div>
</template>

<script>
import { Empty } from 'element-ui';
import { mapState } from 'vuex';
import BarLine from './barLine/Index';

export default {
  name: 'SpecialConfig',
  components: {
    'el-empty': Empty,
    BarLine
  },
  data() {
    return {
    }
  },
  computed: {
    ...mapState({
      chartData: state => state.chart.chartData || null,
    }),
    // 图表类型
    chartType() {
      return this.chartData?.component || '';
    }
  }
}
</script>

<style lang="less" scoped>
.special-config{
  height: 100%;
  width: 100%;
  overflow: hidden;
}
</style>