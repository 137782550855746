/*
 * @Description: 环图
 * @Author: luocheng
 * @Date: 2022-04-27 15:00:42
 * @LastEditors: luocheng
 * @LastEditTime: 2022-05-07 16:33:02
 */
import baseTrans from './baseTrans';

export default {
  ...baseTrans,
  showLabel: {
    type: 'Switch',
    name: '显示说明'
  },
  labelColor: {
    name: '标签颜色',
    type: 'Color'
  },
  labelSize: {
    name: '标签字号',
    type: 'Number'
  },
  colorRange: {
    type: 'ColorRange',
    name: '环图渐变色'
  },
  lessColor: {
    type: 'ColorRange',
    name: '底图颜色'
  },
  clockwise: {
    type: 'Switch',
    name: '逆时针'
  },
  withBorder: {
    type: 'Switch',
    name: '边框'
  },
  barWidth: {
    type: 'Number',
    name: '环宽度'
  },
  centerFontColor: {
    type: 'Color',
    name: '中间文字颜色'
  },
  centerFontSize: {
    type: 'Number',
    name: '中间文字大小'
  },
  centerFontTop: {
    type: 'Number',
    name: '中间文字上偏移(百分比)'
  },
  descType: {
    type: 'Select',
    name: '描述类型',
    options: [
      {
        label: '不显示',
        value: 'none'
      },
      {
        label: '百分比',
        value: 'percent'
      },
      {
        label: '已完成/总数',
        value: 'completed_total'
      },
      {
        label: '仅显示已完成',
        value: 'completed'
      }
    ]
  }
}