/*
 * @Description: 折线图配置项
 * @Author: luocheng
 * @Date: 2022-01-12 15:34:51
 * @LastEditors: luocheng
 * @LastEditTime: 2022-04-29 15:08:09
 */
import baseTrans from './baseTrans';

export default {
  ...baseTrans,
  smooth: {
    type: 'Switch',
    name: '平滑过渡'
  },
  colorRange: {
    type: 'ColorRange',
    name: '折线颜色'
  },
  showDataZoom: {
    name: '显示缩放',
    type: 'Switch'
  },
  dataZoomBottom: {
    name: '下边距',
    type: 'String'
  },
  dataZoomStart: {
    name: '数据开始',
    type: 'Number'
  },
  dataZoomEnd: {
    name: '数据结束',
    type: 'Number'
  },
  userStack: {
    name: '是否使用堆叠',
    type: 'Switch'
  },
  useGradient: {
    name: '使用渐变',
    type: 'Switch'
  },
  gradientRange: {
    name: '渐变颜色数组',
    type: 'Color2Array'
  },
  minInterval: {
    name: '刻度最小间隔',
    type: 'Number'
  },
  useArea: {
    name: '仅使用面积',
    type: 'Switch'

  }
}