<!--
 * @Description: 图表配置
 * @Author: luocheng
 * @Date: 2022-01-05 15:06:49
 * @LastEditors: luocheng
 * @LastEditTime: 2022-05-31 09:37:37
-->
<template>
	<div class="chart-config">
		<article class="config-main">
			<el-collapse
				v-model="activeName"
				accordion
			>
			<el-collapse-item
				title="基本配置"
				name="base"
			>
				<BaseInfo></BaseInfo>
			</el-collapse-item>
				<el-collapse-item
					title="图表类型"
					name="type"
				>
					<ChartTypes></ChartTypes>
				</el-collapse-item>
				<el-collapse-item
					title="数据绑定"
					name="dataSource"
					v-if="chartType"
				>
					<!-- <DataSource></DataSource> -->
					<SourceConfig></SourceConfig>
				</el-collapse-item>
			</el-collapse>
		</article>
	</div>
</template>

<script>
import { Collapse, CollapseItem } from 'element-ui';
import BaseInfo from './BaseInfo';
import ChartTypes from './ChartTypes';
import SourceConfig from './sourceConfig/Index';
import { mapState } from 'vuex';

export default {
	name: 'BaseConfig',
	components: {
		'el-collapse': Collapse,
		'el-collapse-item': CollapseItem,
		BaseInfo,
		ChartTypes,
		SourceConfig
	},
	data() {
		return {
			activeName: 'base'
		};
	},
	computed: {
		...mapState({
			chartData: (state) => state.chart.chartData || null
		}),
		chartType() {
			return this.chartData?.type;
		}
	}
};
</script>

<style lang="less" scoped>
.chart-config {
	height: 100%;
	width: 100%;
	display: flex;
	flex-direction: column;
	overflow: hidden;
	.update {
		margin-top: 10px;
	}
	.config-main {
		flex: 1;
		width: 100%;
		overflow: hidden;
		overflow-y: auto;
		box-sizing: border-box;
		padding: 10px 0;
	}
	:deep(.el-collapse) {
		border-top: none;
	}
}
</style>