/*
 * @Description: 雷达图
 * @Author: luocheng
 * @Date: 2022-01-13 18:06:50
 * @LastEditors: luocheng
 * @LastEditTime: 2022-01-14 09:35:12
 */
import baseTrans from './baseTrans';


export default {
  ...baseTrans,
  colorRange: {
    type: 'ColorRange',
    name: '线条颜色'
  },
  indicator: {
    type: 'RadarIndicator',
    name: '标志位'
  }
}