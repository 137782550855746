<!--
 * @Description: 仪表盘配置
 * @Author: luocheng
 * @Date: 2022-09-26 10:24:37
 * @LastEditors: luocheng
 * @LastEditTime: 2022-09-26 19:28:09
-->
<template>
	<div class="config-pointer">
    <h3>取值配置</h3>
		<el-form :model="configForm" label-width="90px">
			<el-form-item label="显示值取值">
				<el-select v-model="configForm.valueType" placeholder="清选择显示值取值方式" @change="onEmit">
					<el-option label="固定值" value="fixed"></el-option>
					<el-option label="动态取值" value="interface"></el-option>
				</el-select>
			</el-form-item>
			<el-form-item :label="valueLabel">
				<el-input
					v-model="configForm.valueField"
					placeholder="请输入值"
					v-if="configForm.valueType === 'fixed'"
          @input="onEmit"
				></el-input>
				<SelectCode
          v-else
          v-model="configForm.valueField"
          :options="fieldList"
          @input="onEmit">
        </SelectCode>
			</el-form-item>
      <el-form-item label="总量取值">
				<el-select v-model="configForm.totalType" placeholder="清选择总数取值方式" @change="onEmit">
					<el-option label="固定值" value="fixed"></el-option>
					<el-option label="动态取值" value="interface"></el-option>
				</el-select>
			</el-form-item>
			<el-form-item :label="totalLabel">
				<el-input
					v-model="configForm.totalField"
					placeholder="请输入值"
					v-if="configForm.totalType === 'fixed'"
          @input="onEmit"
				></el-input>
				<SelectCode
          v-else
          v-model="configForm.totalField"
          :options="fieldList"
          @input="onEmit">
        </SelectCode>
			</el-form-item>
		</el-form>
	</div>
</template>

<script>
import SelectCode from '../SelectCode';

export default {
  components: {
    SelectCode
  },
	name: 'ConfigPointer',
	props: {
		value: {
			type: Object,
			required: true,
			default: () => {}
		},
		fieldList: {
			type: Array,
			default: () => [],
			required: true
		}
	},
	data() {
		return {
			configForm: {}
		};
	},
	computed: {
		valueLabel() {
			const { valueType } = this.configForm;
			return valueType === 'fixed' ? '显示值' : '显示值字段';
		},
    totalLabel() {
      const { totalType } = this.configForm;
			return totalType === 'fixed' ? '总量' : '总量字段';
    }
	},
	created() {
		this.configForm = this.value || {
			totalType: 'fixed',
			totalField: 120,
			valueType: 'interface',
			valueField: ''
		};
	},
  methods: {
    onEmit() {
      this.$emit('input', this.configForm);
    }
  }
};
</script>

<style lang="less" scoped>
.config-pointer {
	width: 100%;
  h3 {
    text-align: left;
  }
}
</style>
