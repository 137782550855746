<template>
  <div class="fill-data-container">
    <!-- <h3>数据填充</h3> -->
    <table class="data-table" border="1">
      <tr>
        <td>
         <div class="row-col">
           |
         </div>
        </td>
        <td v-for="(item, index) in data.col" :key="item.fild" >
          <div class="name-td-warp">
            <input v-model="item.name" />
            <div class="col-delete" @click.stop="doDeleteCol(index)">
              <i style="color: red" class="iconfont icongis_shanchu" />
            </div>
          </div>
        </td>
        <td>
          <span class="action-btn" @click.stop="doAddCol">
            <i class="iconfont iconcujia" />
          </span>
        </td>
      </tr>
      <tr v-for="(item, index) in data.data" :key="index">
        <td>
          <div class="name-td-warp">
            <input v-model="item.name" />
            <div class="col-delete" @click.stop="doDeleteRow(index)">
              <i style="color: red" class="iconfont icongis_shanchu" />
            </div>
          </div>
        </td>
        <td v-for="(fild, index) in data.col" :key="index">
          <input type="number" min="0" max="100000000000" v-model="item[fild.fild]" />
        </td>
      </tr>
      <tr>
        <td>
          <span class="action-btn" @click.stop="doAddRow">
            <i class="iconfont iconcujia" />
          </span>
        </td>
      </tr>
    </table>
    <div class="help">
      <h4>填写帮助</h4>
      <article>
        编辑后请保存刷新再查看；
      </article>
      <article>
        柱状图、折线图、折柱混合图，行为数据项，列为数据项内内容，行名对应lengde, 列名对应X轴；
      </article>
      <article>
        饼状图仅支持单行数据；
      </article>
      <article>
        进度环图第一个值 为当前值；第二个值 为总值；
      </article>
    </div>
    <footer slot="footer" class="dialog-footer">
      <el-button @click="$emit('cancle')">取 消</el-button>
      <el-button type="primary" @click="doSubmitData">确 定</el-button>
    </footer>
  </div>
</template>
<script>
import { deepCopy } from '@/utils/tools'
export default{
  name: 'FillDataModel',
  props: {
    dataSource: {
      type: Object,
      default() {
        return {}
      },
      require: true
    }
  },
  data() {
    return {
      data: {
        col: [],
        data: []
      }
    };
  },
  methods: {
    doAddCol() {
      const len = this.data.col.length;
      this.data.col.push(this.getCol(len));
    },
    doDeleteCol(index) {
      this.$confirm('删除后不可恢复, 是否继续?', '提示', {
        confirmButtonText: '删除',
        cancelButtonText: '取消',
        type: 'error'
      }).then(() => {
        this.data.col.splice(index, 1);
      }).catch(() => {
        // 没有什么需要操作的         
      });
    },
    getKey() {
      const num = Math.floor(Math.random() * 100);
      return `${num}${new Date().getTime()}`;
    },
    getCol(len) {
      const prefix = Math.floor(len / 26);
      const suffix = len % 26;
      const colName =  prefix > 0 ? `${String.fromCharCode(prefix + 65)}${String.fromCharCode(suffix + 65)}` : `${String.fromCharCode(suffix + 65)}`;
      return {
        name: colName,
        fild: `${colName}_${this.getKey()}`
      };
    },
    doAddRow() {
      const len = this.data.data.length;
      this.data.data.push({
        name: len + 1
      });
    },
    doDeleteRow(index) {
      this.$confirm('删除后不可恢复, 是否继续?', '提示', {
        confirmButtonText: '删除',
        cancelButtonText: '取消',
        type: 'error'
      }).then(() => {
        this.data.data.splice(index, 1);
      }).catch(() => {
        // 没有什么需要操作的         
      });
      
    },
    doSubmitData() {
      if(this.data.col.some(el => !el.name)) {
        this.$message.error('请将列名补充完整！');
        return
      }
      if(this.data.data.some(el => !el.name)) {
        this.$message.error('请将行名补充完整！');
        return
      }
      const data = deepCopy(this.data);
      const filds = data.col.map(el => el.fild);
      data.data = data.data.map(el => {
        const obj = {
          name: el.name
        };
        filds.forEach(ele => {
          obj[ele] = el[ele] || '0';
        });
        return obj;
      })
      this.$emit('submit', data);
    },
    reShowData() {
      const data = deepCopy(this.dataSource);
      for (const key in data) {
        if (Object.hasOwnProperty.call(data, key)) {
          this.$set(this.data, key, data[key]);
        }
      }
    }
  },
  created() {
    this.reShowData();
  }
}
</script>
<style lang="less" scoped>
  .fill-data-container{
    .data-table{
      border: 1px solid #f1f1f1;
      border-collapse: collapse;
      border-color: #f1f1f1;
      width: 100%;
      overflow: auto;
      display: flex;
      flex-direction: column;
      max-height: 60vh;
      tr{
        &:hover{
          background: #f5f5f5;
        }
        td{
          width: 90px;
          min-width: 90px;
          padding: 6px;
          .action-btn{
            color: #409eff;
          }
          input{
            width: 100%;
            border: none;
            background: transparent;
          }
          .row-col{
            &::before{
              content: '行';
            }
            &::after{
              content: '列';
            }
          }
        }
        .name-td-warp{
          display: flex;
          align-items: center;
          .col-delete{
            visibility: hidden;
          }
          &:hover{
            .col-delete{
              visibility: visible;
            }
          }
        }
      }
    }
    .dialog-footer{
      margin-top: 16px;
      text-align: right;
    }
  }
  
</style>