<!--
 * @Description: 下拉
 * @Author: luocheng
 * @Date: 2022-01-11 09:47:22
 * @LastEditors: luocheng
 * @LastEditTime: 2022-01-12 14:07:53
-->
<template>
	<div class="base-select base-com" v-if="bindVal !== null">
		<p class="label">{{ field }}</p>
    <el-select v-model="bindVal" :placeholder="'请选择' + field" clearable @change="updateVal" size="mini">
      <el-option v-for="option in options" :key="option.value"
        :label="option.label"
        :value="option.value"
      ></el-option>
    </el-select>
	</div>
</template>

<script>
import baseMixin from './base';

export default {
	name: 'BaseSelect',
	mixins: [baseMixin],
  props: {
    options: {
      type: Array,
      default: () => []
    }
  }
};
</script>

<style lang="less" scoped>
.base-com{
  display: flex;
  padding-bottom: 10px;
  .label{
    min-width: 80px;
    max-width: 200px;
    line-height: 28px;
    font-size: 14px;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    margin-right: 10px;
  }
}
</style>