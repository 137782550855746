<!--
 * @Description: 静态统计Item
 * @Author: luocheng
 * @Date: 2022-04-07 11:40:48
 * @LastEditors: luocheng
 * @LastEditTime: 2022-09-26 19:28:21
-->
<template>
  <div class="static-item" v-if="configForm">
    <el-form :model="configForm" label-width="80px">
      <el-form-item label="数据对象">
        <SelectObjectUUID v-model="configForm.objectUUID" @input="onObjectUUID"></SelectObjectUUID>
      </el-form-item>
      <el-form-item label="数据视图" v-if="configForm.objectUUID">
        <SelectViewUUID v-model="configForm.viewUUID" :objectUUID="configForm.objectUUID" @input="onGetField"></SelectViewUUID>
      </el-form-item>
      <el-form-item label="图表类型">
        <el-select v-model="configForm.chartType" placeholder="请选择图表类型" clearable @change="onEmit">
          <el-option label="柱状图" value="bar"></el-option>
          <el-option label="折线图" value="line"></el-option>
          <el-option label="仪表盘" value="pointer"></el-option>
        </el-select>
      </el-form-item>
      <template v-if="chartType === 'completionRatio'">
        <!-- 横向进度条 -->
        <ConfigCompletion v-model="configForm.completionRatio" :fieldList="fieldList"></ConfigCompletion>
      </template>
      <template v-else-if="chartType === 'pointer'">
        <!-- 仪表盘 -->
        <ConfigPointer v-model="configForm.pointer" :fieldList="fieldList"></ConfigPointer>
      </template>
      <template v-else>
        <section class="config-box">
          <h3>X轴(已完成)</h3>
          <div  v-for="(item, index) in configForm.x_fields" :key="index">
            <el-form-item label="字段">
              <SelectCode v-model="item.uuid" :options="fieldList" @input="onEmit"></SelectCode>
            </el-form-item>
            <el-form-item label="名称">
              <SelectCode v-model="item.name" :options="fieldList" :valueField="'name'" @input="onEmit"></SelectCode>
            </el-form-item>
            <!-- <el-form-item label="code">
              <SelectCode v-model="item.code" :options="fieldList" :valueField="'code'"></SelectCode>
            </el-form-item> -->
          </div>
        </section>
        <section class="config-box y-axis-box">
          <h3>Y轴(总数)</h3>
          <div  v-for="(item, index) in configForm.y_fields" :key="index">
            <div class="y-main">
              <el-form-item label="字段">
                <SelectCode v-model="item.uuid" :options="fieldList" @input="onEmit"></SelectCode>
              </el-form-item>
              <el-form-item label="名称">
                <SelectCode v-model="item.name" :options="fieldList" :valueField="'name'" @input="onEmit"></SelectCode>
              </el-form-item>
              <!-- <el-form-item label="code">
                <SelectCode v-model="item.code" :options="fieldList" :valueField="'code'"></SelectCode>
              </el-form-item> -->
            </div>
            <i class="el-icon-remove" @click="onRemoveY(index)"></i>
          </div>
          <el-button type="success" size="mini" @click="onPlusY">新增分组</el-button>
        </section>
      </template>
    </el-form>
    <section class="remove">
      <i class="el-icon-delete" @click="onRemoveItem"></i>
    </section>
  </div>
</template>

<script>
import SelectObjectUUID from '@/components/common/SelectObjectUUID';
import SelectViewUUID from '@/components/common/SelectViewUUID';
import { getFields } from '@/apis/data';
import SelectCode from './SelectCode';
import { mapState } from 'vuex';
import ConfigCompletion from './completionRatio/Index';
import ConfigPointer from './pointer/Index';

export default {
  name: 'StaticItem',
  components: {
    SelectObjectUUID,
    SelectViewUUID,
    SelectCode,
    ConfigCompletion,
    ConfigPointer
  },
  props: {
    data: {
      type: Object,
      required: true,
      default: () => {}
    },
    index: {
      type: Number,
      required: true
    }
  },
  data() {
    return {
      configForm: null,
      // {
      //   objectUUID: '',
      //   viewUUID: '',
      //   x_field: [
      //     {
      //       uuid: '',
      //       code: '',
      //       name: ''
      //     }
      //   ],
      //   y_fields: [
      //     {
      //       uuid: '',
      //       code: '',
      //       name: ''
      //     }
      //   ]
      // }
      // 字段列表
      fieldList: []
    }
  },
  computed: {
    ...mapState({
      chartData: state => state.chart.chartData || null,
    }),
    chartType() {
      return this.chartData?.type || '';
    }
  },
  created() {
    this.configForm = {
      chartType: 'bar',
      pointer: {
        totalType: 'fixed',
        totalField: 120,
        valueType: 'interface',
        valueField: ''
      },
      ...JSON.parse(JSON.stringify(this.data))
    };
    if (this.configForm.objectUUID && this.configForm.viewUUID) {
      this.onGetField();
    }
  },
  methods: {
    /**
     * @desc: 切换对象UUID
     */
    onObjectUUID() {
      const objectUUID = this.configForm.objectUUID;
      this.configForm = null;
      this.fieldList = [];
      this.$nextTick(() => {
        this.configForm = {
          objectUUID,
          viewUUID: '',
          x_fields: [
            {
              uuid: '',
              code: '',
              name: ''
            }
          ],
          y_fields: [
            {
              uuid: '',
              code: '',
              name: ''
            }
          ],
          completionRatio: [],
          pointer: {
            totalType: 'fixed',
            totalField: 120,
            valueType: 'interface',
            valueField: ''
          }
        };
      });
      this.onEmit();
    },
    /**
     * @desc: 新增Y轴分组
     */
    onPlusY() {
      this.configForm.y_fields.push({
        uuid: '',
        code: '',
        name: ''
      });
    },
    /**
     * @desc: 移除
     * @param {Number} index
     */
    onRemoveY(index) {
      this.configForm.y_fields.splice(index, 1);
    },
    /**
     * @desc: 获取字段
     */
    onGetField() {
      getFields({
        object_uuid: this.configForm.objectUUID,
        view_uuid: this.configForm.viewUUID,
        size: 1000,
        page: 1
      }).then(res => {
        this.fieldList = res?.data?.data?.data || [];
      }).catch(err => {
        console.log(err);
        this.$message.error('获取字段失败！');
      })
      this.onEmit();
    },
    /**
     * @desc: 提交数据
     */
    onEmit() {
      this.$emit('update', {
        data: {
          ...this.configForm
        },
        index: +this.index
      });
    },
    /**
     * @desc: 移除
     */
    onRemoveItem() {
      this.$confirm('是否确认删除?').then(() => {
        this.$emit('remove', this.index);
      }).catch(() => {});
    }
  }
}
</script>

<style lang="less" scoped>
.static-item{
  display: flex;
  margin-top: 16px;
	border-radius: 4px;
	width: 100%;
	border: 1px solid #f2f3f5;
	box-sizing: border-box;
	padding: 10px 10px 0 10px;
	box-shadow: 2px 2px 8px 2px rgba(0, 0, 0, 0.1);
  .el-form-item{
    margin-bottom: 10px;
  }
  .config-box{
    h3{
      position: relative;
      line-height: 20px;
      text-align: left;
      font-size: 14px;
      text-indent: 10px;
      &::before{
        position: absolute;
        content: '';
        width: 3px;
        height: 12px;
        top: 4px;
        left: 0;
        border-radius: 3px;
        background: @theme;
      }
    }
    &.y-axis-box{
      .el-button{
        margin-bottom: 10px;
      }
      &>div{
        display: flex;
        border: 1px solid #f2f3f5;
        margin: 5px 0;
        box-sizing: border-box;
        padding: 10px 5px 0 5px;
        border-radius: 5px;
        margin-bottom: 10px;
        i{
          cursor: pointer;
          line-height: 80px;
          padding-left: 10px;
          font-size: 16px;
          color: @dangerColor;
        }
      }
    }
  }
  .el-form{
    flex: 1;
  }
  .remove{
    box-sizing: border-box;
    padding-left: 10px;
    .el-icon-delete{
      font-size: 18px;
      color: @dangerColor;
      line-height: 180px;
    }
  }
}
</style>