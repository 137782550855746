<!--
 * @Description: 颜色集合
 * @Author: luocheng
 * @Date: 2022-01-12 14:17:18
 * @LastEditors: luocheng
 * @LastEditTime: 2022-05-23 16:21:02
-->
<template>
	<div class="base-color-2-array-container base-com" v-if="bindVal">
    <p class="label">{{ field }}</p>
    <div class="color-list">
      <div v-for="(item, index) in bindVal" :key="index" class="color-item">
        <div class="index">
          {{ index + 1 }}
        </div>
        <div class="color-item-tags">
          <el-tag v-for="(sitem, sindex) in item" :key="sindex"
            :color="sitem"
            closable
            @close="onRemove(index, sindex)"
          >{{ sitem }}</el-tag>
          <el-color-picker v-model="newColor[index]" show-alpha @change="(res) => { pushNewColor(res, index) }"></el-color-picker>
        </div>
        <el-button type="danger" @click.stop="removeOne(index)">移除</el-button>
      </div>
      <el-button type="primary" style="width: 100%; margin-top: 5px;" @click.stop="handleAdd">
        新增
      </el-button>
    </div>
  </div>
</template>

<script>
import { ColorPicker, Tag } from 'element-ui';
import baseMixin from './base';

export default {
	name: 'BaseColorRange',
  components: {
    'el-color-picker': ColorPicker,
    'el-tag': Tag
  },
	mixins: [baseMixin],
  data() {
    return {
      newColor: {}
    }
  },
  methods: {
    /**
     * @desc: 新增颜色
     * @param {String} color 选中的颜色
     */
    pushNewColor(color, index) {
      if(color) {
      this.bindVal[index].push(color);
      this.updateVal();
      }
    },
    /**
     * @desc: 删除颜色
     * @param {Number} index 下标
     */
    onRemove(index, sindex) {
      this.bindVal[index].splice(sindex, 1);
      this.updateVal();
    },
    /**
    * @desc: 删除一项颜色组
    */
    removeOne(index) {
      this.bindVal.splice(index, 1);
      this.updateVal();
    },
    /**
    * @desc: 新增一项颜色组
    */
    handleAdd() {
      this.bindVal.push([]);
      this.updateVal();
    }
  }
};
</script>

<style lang="less" scoped>
.base-com{
  display: flex;
  padding-bottom: 10px;
  .label{
    min-width: 80px;
    max-width: 200px;
    line-height: 28px;
    font-size: 14px;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    margin-right: 10px;
  }
  .color-list{
    .color-item{
      .index{
        width: 100%;
        text-align: left;
      }
      .color-item-tags{
        display: flex;
        flex-wrap: wrap;
      }
    }
  }
}
</style>