/*
 * @Description: 配置说明控制
 * @Author: luocheng
 * @Date: 2022-01-11 10:15:49
 * @LastEditors: luocheng
 * @LastEditTime: 2022-09-26 18:43:42
 */
import bar from './bar';
import line from './line';
import pie from './pie';
import flop from './flop';
import funnel from './funnel';
import radar from './radar';
import ring from './ring';
import completionRatio from './completionRatio';
import barLine from './barLine';
import pointer from './pointer';

export default {
  bar,
  line,
  barLine,
  pie,
  flop,
  funnel,
  radar,
  ring,
  completionRatio,
  pointer
}