<!--
 * @Description: 横坐标配置
 * @Author: luocheng
 * @Date: 2022-06-22 10:36:41
 * @LastEditors: luocheng
 * @LastEditTime: 2022-06-22 14:34:05
-->
<template>
  <div class="set-x-data">
    <header class="header">
      <h3>图例【{{ list.length }}个】</h3>
      <a href="javascript:;" class="plus" @click="onPlus">新增</a>
      <a href="javascript:;" @click="onToggle" class="toggle">
        {{ showToggle ? '收起' : '展开' }}
      </a>
    </header>
    <ul class="list" v-show="showToggle">
      <p class="no-data" v-if="!list.length">暂无数据</p>
      <template v-else>
        <XItem v-for="(item, index) in list" :key="index"
          :config="item"
          :index="index"
          @update="onUpdate"
          @remove="onRemove"
        >
        </XItem>
      </template>
    </ul>
  </div>
</template>

<script>
import XItem from './xItem';

export default {
  name: 'SetXData',
  components: {
    XItem
  },
  props: {
    value: {
      type: Array,
      required: true,
      default: () => []
    }
  },
  data() {
    return {
      list: [],
      showToggle: false
    }
  },
  created() {
    this.list = this.value;
  },
  methods: {
    /**
     * @desc: 新增
     */
    onPlus() {
      this.list.push('');
      this.onEmit();
    },
    /**
     * @desc: 更新
     * @param {Object} data
     * @param {Number} index
     */
    onUpdate({ data, index }) {
      this.list.splice(index, 1, data);
      this.onEmit();
    },
    /**
     * @desc: 切换
     */
    onToggle() {
      this.showToggle = !this.showToggle;
    },
    /**
     * @desc: 移除
     * @param {Number} index
     */
    onRemove(index) {
      this.list.splice(index, 1);
      this.onEmit();
    },
    /**
     * @desc: 提交数据
     */
    onEmit() {
      this.$emit('input', this.list);
    }
  }
}
</script>

<style lang="less" scoped>
.set-x-data{
  width: 100%;
  padding: 10px 0;
  border-radius: 6px;
  border-bottom: 2px solid #f2f3f5;
  .header{
    display: flex;
    h3{
      position: relative;
      flex: 1;
      padding-left: 5px;
      font-size: 14px;
      text-align: left;
      box-sizing: border-box;
      line-height: 24px;
      &::before{
        content: '';
        position: absolute;
        display: block;
        height: 14px;
        width: 3px;
        border-radius: 1px;
        top: 5px;
        left: 0;
        background: @theme;
      }
    }
    a{
      box-sizing: border-box;
      padding: 0 5px;
      font-size: 14px;
      line-height: 24px;
      &.plus{
        color: @successColor;
      }
      &.toggle{
        color: @theme;
      }
    }
  }
  .list{
    margin-top: 10px;
    .no-data{
      padding: 10px 0;
      color: @minorColor;
    }
  }
}
</style>