/*
 * @Description: 配置项
 * @Author: luocheng
 * @Date: 2022-07-26 17:52:35
 * @LastEditors: luocheng
 * @LastEditTime: 2022-07-26 17:57:08
 */
// top / left / right / bottom / inside / insideLeft / insideRight / insideTop / insideBottom / insideTopLeft / insideBottomLeft / insideTopRight / insideBottomRight
// 位置信息备选项
export const positionOptions = [{
  "label": "顶部",
  "value": "top"
}, {
  "label": "居左",
  "value": "left"
}, {
  "label": "居右",
  "value": "right"
}, {
  "label": "底部",
  "value": "bottom"
}, {
  "label": "内部",
  "value": "inside"
}, {
  "label": "内部居左",
  "value": "insideLeft"
}, {
  "label": "内部居右",
  "value": "insideRight"
}, {
  "label": "内部顶部",
  "value": "insideTop"
}, {
  "label": "内部底部",
  "value": "insideBottom"
}, {
  "label": "内部左上",
  "value": "insideTopLeft"
}, {
  "label": "内部左下",
  "value": "insideBottomLeft"
}, {
  "label": "内部右上",
  "value": "insideTopRight"
}, {
  "label": "内部右下",
  "value": "insideBottomRight"
}]