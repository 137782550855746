<!--
 * @Description: 配置模板
 * @Author: luocheng
 * @Date: 2022-01-10 17:56:00
 * @LastEditors: luocheng
 * @LastEditTime: 2022-09-26 18:41:38
-->
<template>
	<div class="base-template">
		<header class="header">
			<span class="line"></span>
			<p class="title">基本属性</p>
			<el-button type="warning" size="mini" @click="onSync">同步</el-button>
			<el-button type="primary" size="mini" @click="onConfirm">确认配置</el-button>
			<el-button type="text" style="color: #409eff" size="mini" @click="onToggle">
				{{ isShow ? '收起' : '展开' }}
			</el-button>
		</header>
		<article class="config-main" v-show="isShow">
			<p class="no-data" v-if="!configForm || !chartType || !configTranscode">暂无配置</p>
			<template v-else>
				<!-- 遍历 -->
				<section class="config-item" v-for="(value, key) in configForm" :key="key">
					<template v-if="configTranscode[key]">
						<component
							:is="'Base' + configTranscode[key].type"
							v-model="configForm[key]"
							:field="configTranscode[key].name"
							:options="configTranscode[key].type === 'Select' ? configTranscode[key].options : []"
						></component>
					</template>
				</section>
			</template>
		</article>
	</div>
</template>

<script>
import { mapState } from 'vuex';
// 注册组件
const componentsList = require.context('./components', true, /\.+vue$/);
const customComponents = {};
componentsList.keys().forEach((fileName) => {
	let name = fileName.replace(/.vue/, '');
	name = name.replace(/\.\//, '');
	if (name.indexOf('/') < 0) {
		// name = name.split('/')[1];
		customComponents[name] = componentsList(fileName).default;
	}
});
import transcode from '../transcode/index';
import { configObject } from '@/chart-components/config.js';

export default {
	name: 'BaseTemplate',
	components: {
		...customComponents
	},
	data() {
		return {
			isShow: false,
			configForm: null,
			transcode
		};
	},
	computed: {
		...mapState({
			chartData: (state) => state.chart.chartData || null
		}),
		// 属性
		attributes() {
			return this.chartData?.attributes;
		},
		// 图表类型
		chartType() {
			return this.chartData?.type || '';
		},
		// 类型
		configTranscode() {
			if (!this.chartType) return null;
			return this.transcode[this.chartType];
		}
	},
	created() {
		console.log(this.chartData, '---chart-data-----')
		this.configForm = this.attributes;
	},
	methods: {
		/**
		 * @desc: 同步最新配置
		 */
		onSync() {
			const target = configObject[this.chartType];
			console.log(target, '---324---', target.attributes);
			if (target && target.attributes) {
				this.$confirm('是否确认同步最新配置(保留当前已配置项)!')
					.then(() => {
						this.$store.commit('setChartData', {
							...this.chartData,
							attributes: {
								...target.attributes,
								...this.configForm
							}
						});
						this.configForm = this.attributes;
					})
					.catch(() => {});
			}
		},
		/**
		 * @desc: 确认配置
		 */
		onConfirm() {
			this.$store.commit('setChartData', {
				...this.chartData,
				attributes: this.configForm
			});
		},
		/**
		 * @desc: 切换显示
		 */
		onToggle() {
			this.isShow = !this.isShow;
		}
	}
};
</script>

<style lang="less" scoped>
.base-template {
	width: 100%;
	border: 1px solid #f2f3f5;
	box-sizing: border-box;
	padding: 4px 8px;
	border-radius: 6px;
	cursor: pointer;
	&:hover {
		box-shadow: 2px 2px 8px 2px rgba(0, 0, 0, 0.1);
	}
	.header {
		display: flex;
		.line {
			height: 12px;
			width: 3px;
			border-radius: 1px;
			background: @theme;
			margin-top: 8px;
			margin-right: 5px;
		}
		.title {
			flex: 1;
			line-height: 28px;
			overflow: hidden;
			text-overflow: ellipsis;
			white-space: nowrap;
			text-align: left;
			font-size: 14px;
		}
		.el-button {
			padding-right: 10px;
		}
	}
	.config-main {
		padding-top: 10px;
		.no-data {
			line-height: 50px;
			text-align: center;
			color: #ccc;
		}
	}
}
</style>
