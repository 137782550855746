<!--
 * @Description: 单个绑定配置
 * @Author: luocheng
 * @Date: 2022-06-22 14:42:07
 * @LastEditors: luocheng
 * @LastEditTime: 2022-06-22 17:16:27
-->
<template>
  <div class="bind-item">
    <el-form :model="configForm" label-width="100px" v-if="configForm">
      <el-form-item label="图表类型">
        <el-select v-model="configForm.chartType" placeholder="请选择图表类型" @change="onEmit">
          <el-option label="柱状图" value="bar"></el-option>
          <el-option label="折线图" value="line"></el-option>
        </el-select>
      </el-form-item>
      <el-form-item label="数据对象">
        <SelectObjectUUID v-model="configForm.objectUUID" @input="onObjectUUID"></SelectObjectUUID>
      </el-form-item>
      <el-form-item label="数据视图" v-if="configForm.objectUUID">
        <SelectViewUUID v-model="configForm.viewUUID" :objectUUID="configForm.objectUUID" @input="onGetField"></SelectViewUUID>
      </el-form-item>
      <!-- <el-form-item label="请求方式">
        <el-select v-model="configForm.methodName" placeholder="请选择请求方式" @change="onGetField">
          <el-option label="列表" value="dataList"></el-option>
          <el-option label="详情" value="dataInfo"></el-option>
        </el-select>
      </el-form-item> -->
      <el-form-item label="绑定图例(数据分类)">
        <el-select v-model="configForm.bindLegend" placeholder="请选择图例(数据分类)" @change="onEmit">
          <el-option v-for="(item, index) in legendData"
            :key="index"
            :label="item"
            :value="item"
          ></el-option>
        </el-select>
      </el-form-item>
      <section class="value-bind">
        <p class="title">字段绑定</p>
        <ValueItem v-for="(vItem, vIndex) in configForm.valueConfig"
          :key="vIndex"
          :index="vIndex"
          :config="vItem"
          :fieldList="fieldList"
          @update="onUpdateValueBind"
        ></ValueItem>
      </section>
    </el-form>
    <section class="action">
      <i class="el-icon-delete" @click="onRemove"></i>
    </section>
  </div>
</template>

<script>
import SelectObjectUUID from '@/components/common/SelectObjectUUID';
import SelectViewUUID from '@/components/common/SelectViewUUID';
import { dataInterface } from '@/apis/data';
import ValueItem from './ValueItem';

export default {
  name: 'BindItem',
  components: {
    SelectObjectUUID,
    SelectViewUUID,
    ValueItem
  },
  props: {
    config: {
      type: Object,
      required: true,
      default: () => {}
    },
    index: {
      type: Number,
      required: true,
      default: 0
    },
    legendData: {
      type: Array,
      required: true,
      default: () => []
    }
  },
  data() {
    return {
      configForm: null,
      // {
      //   objectUUID: '',
      //   viewUUID: '',
      //   methodName: '', // 列表或许详情，列表会取第一条，详情直接取对象
      //   bindLegend: '', // 绑定的图例 每条线对应一个图例
      //   valueConfig: [
      //     {
      //       name: '',
      //       fieldUUID: '', // 绑定字段
      //       code: ''
      //     }
      //   ]
      // }
      // 字段列表 Object
      fieldList: {}
    }
  },
  created() {
    this.configForm = {
      chartType: 'line',
      ...this.config
    }
    this.onGetField();
  },
  methods: {
    /**
     * @desc: 切换对象
     */
    onObjectUUID() {
      this.configForm.viewUUID = '';
      this.onEmit();
    },
    /**
     * @desc: 获取相关字段
     */
    onGetField() {
      const { methodName, objectUUID, viewUUID } = this.configForm;
      if (!methodName || !objectUUID || !viewUUID) return;
      this.$loading();
      this.fieldList = {};
      dataInterface({
        __method_name__: methodName || 'dataList',
        object_uuid: objectUUID,
        view_uuid: viewUUID
      }).then(res => {
        this.$loading().close();
        if (res.status !== 200) return;
        this.fieldList = res?.data?.metadata || {};
      }).catch(err => {
        console.log(err);
        this.$loading().close();
      });
    },
    /**
     * @desc: 更新绑定
     */
    onUpdateValueBind({ config, index }) {
      const { valueConfig = [] } = this.configForm;
      valueConfig.splice(index, 1, config)
      this.configForm = {
        ...this.configForm,
        valueConfig
      };
      this.onEmit();
    },
    /**
     * @desc: 移除
     */
    onRemove() {
      this.$emit('remove', this.index);
    },
    onEmit() {
      this.$emit('update', {
        data: this.configForm,
        index: this.index
      });
    }
  }
}
</script>

<style lang="less" scoped>
.bind-item{
  border: 1px solid #f2f3f5;
  width: 100%;
  box-sizing: border-box;
  padding: 5px;
  display: flex;
  .el-form{
    flex: 1;
    .el-form-item{
      margin-bottom: 10px;
    }
    .value-bind{
      .title{
        border-left: 2px solid @theme;
        line-height: 14px;
        font-size: 14px;
        text-align: left;
        text-indent: 20px;
        margin: 3px 0;
      }
    }
  }
  .action{
    padding: 0 5px;
    i{
      font-size: 14px;
      line-height: 120px;
      font-weight: bold;
      color: @dangerColor;
    }
  }
}
</style>