<!--
 * @Description: 折柱混合
 * @Author: luocheng
 * @Date: 2022-07-26 16:05:37
 * @LastEditors: luocheng
 * @LastEditTime: 2022-07-26 16:44:25
-->
<template>
  <div class="bar-line-config">
    <el-collapse v-model="activeName" accordion>
			<el-collapse-item title="说明文本位置" name="position">
				<SeriesLabel></SeriesLabel>
			</el-collapse-item>
    </el-collapse>
  </div>
</template>

<script>
import { Collapse, CollapseItem } from 'element-ui';
import SeriesLabel from './SeriesLabel';

export default {
  name: 'BarLineSpecial',
  components: {
    'el-collapse': Collapse,
    'el-collapse-item': CollapseItem,
    SeriesLabel
  },
  data() {
    return {
      activeName: 'position'
    }
  }
}
</script>

<style scoped>
.bar-line-config{
  height: 100%;
  width: 100%;
  overflow: hidden;
  overflow-y: auto;
}
</style>