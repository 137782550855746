/*
 * @Description: 
 * @Author: luocheng
 * @Date: 2022-01-12 15:33:40
 * @LastEditors: luocheng
 * @LastEditTime: 2022-05-26 19:09:04
 */
export default {
  showTitle: {
    type: 'Switch',
    name: '显示标题'
  },
  title: {
    type: 'String',
    name: '图形标题'
  },
  textColor: {
    type: 'Color',
    name: '文字颜色'
  },
  titleFontSize: {
    type: 'Number',
    name: '标题字号'
  },
  titleFontWeight: {
    type: 'Number',
    name: '标题字重'
  },
  titleLeft: {
    type: 'String',
    name: '标题距左'
  },
  titleTop: {
    type: 'String',
    name: '标题距上'
  },
  titleRight: {
    type: 'String',
    name: '标题距右'
  },
  titleBottom: {
    type: 'String',
    name: '标题距下'
  },
  showTooltip: {
    type: 'Switch',
    name: '显示提示框'
  },
  showLegend: {
    type: 'Switch',
    name: '显示图例'
  },
  legendTextColor: {
    type: 'Color',
    name: '图例文字颜色'
  },
  axisFontSize: {
    type: 'Number',
    name: 'XY轴字号'
  },
  axisTextColor: {
    type: 'Color',
    name: 'XY轴文字颜色'
  },
  xAxisText: {
    type: 'String',
    name: 'X轴单位'
  },
  xAxisTextFontSize: {
    type: 'Number',
    name: 'X轴单位字号'
  },
  xAxisTextColor: {
    type: 'Color',
    name: 'X轴单位颜色'
  },
  yAxisText: {
    type: 'String',
    name: 'Y轴单位'
  },
  yAxisTextFontSize: {
    type: 'Number',
    name: 'Y轴单位字号'
  },
  yAxisTextColor: {
    type: 'Color',
    name: 'Y轴单位颜色'
  },
  legendIcon: {
    type: 'Select',
    name: '图例图标',
    options: [
      {
        label: '圆形',
        value: 'circle'
      },
      {
        label: '矩形',
        value: 'rect'
      },
      {
        label: '圆角矩形',
        value: 'roundRect'
      },
      {
        label: '三角',
        value: 'triangle'
      },
      {
        label: '菱形',
        value: 'diamond'
      },
      {
        label: '大头针',
        value: 'pin'
      },
      {
        label: '箭头',
        value: 'arrow'
      },
      {
        label: '无',
        value: 'none'
      }
    ]
  },
  legendTop: {
    name: '图例上边距',
    type: 'String'
  },
  legendBottom: {
    name: '图例下边距',
    type: 'String'
  },
  legendLeft: {
    name: '图例左边距',
    type: 'String'
  },
  legendRight: {
    name: '图例右边距',
    type: 'String'
  },
  legendOrient: {
    name: '图例布局朝向',
    type: 'Select',
    options: [
      {
        label: '水平',
        value: 'horizontal'
      },
      {
       label: '纵向',
       value: 'vertical' 
      }
    ]
  },
  legendType: {
    name: '图例类型',
    type: 'Select',
    options: [
      {
        label: '普通',
        value: 'plain'
      },
      {
        label: '滚动',
        value: 'scroll'
      }
    ]
  },
  top: {
    name: '内容上边距',
    type: 'String'
  },
  right: {
    name: '内容右边距',
    type: 'String'
  },
  left: {
    name: '内容左边距',
    type: 'String'
  },
  bottom: {
    name: '内容下边距',
    type: 'String'
  }
}