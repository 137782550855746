/*
 * @Description: 
 * @Author: luocheng
 * @Date: 2022-05-07 11:00:31
 * @LastEditors: luocheng
 * @LastEditTime: 2022-05-26 18:15:28
 */
export default {
  barHeight: {
    name: '柱体高度',
    type: 'Number'
  },
  barWidth: {
    name: '柱体宽度',
    type: 'String'
  },
  barMarginTop: {
    name: '柱体上边距',
    type: 'Number'
  },
  showTitle: {
    name: '显示柱标题',
    type: 'Switch' 
  },
  titleFontSize: {
    name: '标题字体大小',
    type: 'Number'
  },
  titleColor: {
    name: '标题颜色',
    type: 'Color'
  },
  showCompleteCount: {
    name: '显示完成量',
    type: 'Switch' 
  },
  completeFontSize: {
    name: '完成量字体大小',
    type: 'Number'
  },
  completeColor: {
    name: '标题颜色',
    type: 'Color'
  },
  showPercent: {
    name: '显示百分比',
    type: 'Switch' 
  },
  percentFontSize: {
    name: '百分比字体大小',
    type: 'Number'
  },
  percentColor: {
    name: '百分比颜色',
    type: 'Color'
  },
  completeBg: {
    name: '已完成背景',
    type: 'Color'
  },
  background: {
    name: '柱体背景',
    type: 'Color'
  },
  transparentBg: {
    name: '透明背景',
    type: 'Switch'
  },
  isRadius: {
    name: '显示圆角',
    type: 'Switch'
  },
  showBorder: {
    name: '显示边框',
    type: 'Switch'
  },
  borderColor: {
    name: '边框颜色',
    type: 'Color'
  },
  useGradient: {
    name: '使用渐变',
    type: 'Switch'
  },
  gradientRange: {
    name: '渐变颜色数组',
    type: 'Color2Array'
  }
}