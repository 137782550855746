<!--
 * @Description: 数据绑定配置
 * @Author: luocheng
 * @Date: 2022-06-22 14:39:40
 * @LastEditors: luocheng
 * @LastEditTime: 2022-06-22 17:14:18
-->
<template>
  <div class="bind-config">
    <header class="header">
      <h3>数据来源【{{ list.length }}个】</h3>
      <a href="javascript:;" class="plus" @click="onPlus">新增</a>
      <a href="javascript:;" @click="onToggle" class="toggle">
        {{ showToggle ? '收起' : '展开' }}
      </a>
    </header>
    <article class="bind-box">
      <BindItem v-for="(item, index) in list" :key="index"
        :index="index"
        :config="item"
        @remove="onRemove"
        @update="onUpdate"
        :legendData="legendConfig"
      >
      </BindItem>
    </article>
  </div>
</template>

<script>
import BindItem from './BindItem';

export default {
  name: 'BindConfig',
  components: {
    BindItem
  },
  props: {
    value: {
      type: Array,
      required: true,
      default: () => []
    },
    // x轴配置
    xDataConfig: {
      type: Array,
      required: true,
      default: () => []
    },
    // 图例配置
    legendConfig: {
      type: Array,
      required: true,
      default: () => []
    }
  },
  data() {
    return {
      showToggle: true,
      list: []
    }
  },
  created() {
    this.list = this.value || []
  },
  watch: {
    xDataConfig: {
      handler(newVal) {
        if (!Array.isArray(newVal)) return false;
        // 更新X轴配置之后需要对配置进行更新
        this.list = this.list.map(ele => {
          const { valueConfig = [] } = ele;
          const valueList = valueConfig.map(val => {
            return val.name;
          });
          // 如果不存在则补充进去，
          newVal.forEach(item => {
            if (!valueList.includes(item)) {
              valueConfig.push({
                name: item,
                fieldUUID: '', // 绑定字段
                code: ''
              });
            }
          });
          // 如果被删除则同步移除配置
          valueList.forEach((item, index) => {
            if (!newVal.includes(item)) {
              valueConfig.splice(index, 1);
            }
          });
          return {
            ...ele,
            valueConfig
          };
        });
      },
      deep: true,
      immediate: true
    }
  },
  methods: {
    /**
     * @desc: 新增
     */
    onPlus() {
      this.list.push({
        objectUUID: '',
        viewUUID: '',
        chartType: 'bar',
        methodName: 'dataList', // 列表或许详情，列表会取第一条，详情直接取对象
        bindLegend: '', // 绑定的图例 每条线对应一个图例
        valueConfig: this.setXConfigJSON(this.xDataConfig)
      });
      this.onEmit();
    },
    /**
     * @desc: 根据基础的X轴配置生成基础配置数据JSON
     * @param {Array} xDataConfig 可用的基础配置
     */
    setXConfigJSON(xDataConfig) {
      return xDataConfig.map(name => {
        return {
          name,
          fieldUUID: '', // 绑定字段
          code: ''
        }
      })
    },
    /**
     * @desc: 更新
     * @param {Object} data
     * @param {Number} index
     */
    onUpdate({ data, index }) {
      this.list.splice(index, 1, data);
      this.onEmit();
    },
    /**
     * @desc: 切换
     */
    onToggle() {
      this.showToggle = !this.showToggle;
    },
    /**
     * @desc: 移除
     * @param {Number} index
     */
    onRemove(index) {
      this.list.splice(index, 1);
      this.onEmit();
    },
    /**
     * @desc: 提交数据
     */
    onEmit() {
      this.$emit('input', this.list);
    }
  }
}
</script>

<style lang="less" scoped>
.bind-config{
  width: 100%;
  padding-top: 10px;
  .header{
    display: flex;
    h3{
      position: relative;
      flex: 1;
      padding-left: 5px;
      font-size: 14px;
      text-align: left;
      box-sizing: border-box;
      line-height: 24px;
      &::before{
        content: '';
        position: absolute;
        display: block;
        height: 14px;
        width: 3px;
        border-radius: 1px;
        top: 5px;
        left: 0;
        background: @theme;
      }
    }
    a{
      box-sizing: border-box;
      padding: 0 5px;
      font-size: 14px;
      line-height: 24px;
      &.plus{
        color: @successColor;
      }
      &.toggle{
        color: @theme;
      }
    }
  }
}
</style>