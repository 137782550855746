<!--
 * @Description: 单行
 * @Author: luocheng
 * @Date: 2022-05-07 10:50:45
 * @LastEditors: luocheng
 * @LastEditTime: 2022-05-07 15:31:17
-->
<template>
  <div class="item">
    <el-form :model="formData" label-width="90px">
      <el-form-item label="完成量">
        <SelectCode v-model="formData.complete" :options="fieldList" @input="onEmit"></SelectCode>
      </el-form-item>
      <el-form-item label="总量字段">
        <SelectCode v-model="formData.total" :options="fieldList" @input="onEmit"></SelectCode>
      </el-form-item>
      <el-form-item label="完成量描述">
        <SelectCode v-model="formData.completeText" :options="fieldList" :valueField="'name'" @input="onEmit"></SelectCode>
      </el-form-item>
    </el-form>
    <section class="action">
      <i class="iconfont el-icon-delete" @click="onRemove"></i>
    </section>
  </div>
</template>

<script>
import SelectCode from '../SelectCode';

export default {
  name: 'Item',
  components: {
    SelectCode
  },
  props: {
    // 下标
    index: {
      type: Number,
      required: true
    },
    // 配置
    config: {
      type: Object,
      required: true,
      default: () => {}
    },
    // 字段列表
    fieldList: {
      type: Array,
      default: () => [],
      required: true
    }
  },
  data() {
    return {
      formData: {
        complete: '',
        total: '',
        completeText: ''
      }
    }
  },
  created() {
    this.formData = this.config ? {
      ...this.config
    } : {
      complete: '',
      total: '',
      completeText: ''
    }
  },
  methods: {
    /**
     * @desc: 移除
     */
    onRemove() {
      this.$emit('remove', this.index);
    },
    /**
     * @desc: 提交
     */
    onEmit() {
      this.$emit('update', {
        index: this.index,
        config: this.formData
      });
    }
  }
}
</script>

<style lang="less" scoped>
.item{
  border: 1px solid #f2f3f5;
  padding: 5px;
  border-radius: 6px;
  margin-bottom: 10px;
  display: flex;
  .el-form-item{
    margin-bottom: 10px;
    &:last-of-type{
      margin-bottom: 0;
    }
  }
  .action{
    padding-left: 5px;
    i{
      color: @dangerColor;
      font-weight: bold;
      line-height: 100px;
      cursor: pointer;
    }
  }
}
</style>