<!--
 * @Description: labe单图表配置
 * @Author: luocheng
 * @Date: 2022-07-26 16:43:20
 * @LastEditors: luocheng
 * @LastEditTime: 2022-07-27 14:10:37
-->
<template>
  <div class="series-label-item">
    <header class="header">
      <h3 class="title">{{ configForm.chartType | filterType(index) }}</h3>
      <a href="javascript:;" @click="onToggle">{{ showToggle ? '收起' : '展开' }}</a>
    </header>
    <el-form :model="configForm" v-show="showToggle" label-width="120px">
      <el-form-item label="文本位置">
        <el-select v-model="configForm.position" placeholder="请选择文本位置" @change="onEmit">
          <el-option v-for="item in positionOptions"
            :key="item.value"
            :label="item.label"
            :value="item.value">
          </el-option>
        </el-select>
      </el-form-item>
      <el-form-item label="距离图形元素的距离">
        <span slot="label">
           <el-popover
            placement="top-start"
            title="提示"
            width="200"
            :center="true"
            trigger="hover"
            content="文本位置设置为【顶部】或【内部居右】时候有效">
            <span slot="reference">
              <span>距离图形元素的距离</span>
              <i class="el-icon-question"></i>
            </span>
          </el-popover>
        </span>
        <el-input-number v-model="configForm.distance" @input="onEmit"></el-input-number>
      </el-form-item>
      <el-form-item label="文字块内边距">
        <span slot="label">
           <el-popover
            placement="top-start"
            title="提示"
            width="200"
            :center="true"
            trigger="hover"
            content="文字块的内边距。多个值使用英文输入状态逗号(,)隔开(支持1/2/4个)。例如：
              padding: 3,4,5,6：表示 [上, 右, 下, 左] 的边距。
              padding: 4：表示 padding: [4, 4, 4, 4]。
              padding: 3,4：表示 padding: [3, 4, 3, 4]。
            ">
            <span slot="reference">
              <span>文字块内边距</span>
              <i class="el-icon-question"></i>
            </span>
          </el-popover>
        </span>
        <el-input v-model="configForm.padding" placeholder="请输入文字块内边距" clearable @input="onEmit"></el-input>
      </el-form-item>
    </el-form>
  </div>
</template>

<script>
import { positionOptions } from './config';
import { InputNumber, Popover } from 'element-ui';
// position: 'top', 
// distance: 5,
// padding: 0

export default {
  name: 'SeriesLabelItem',
  components: {
    'el-input-number': InputNumber,
    'el-popover': Popover
  },
  props: {
    index: {
      type: Number,
      default: 0,
      required: true
    },
    config: {
      type: Object,
      default: () => {},
      required: true
    }
  },
  data() {
    return {
      configForm: {
        chartType: '',
        position: 'top',
        distance: 5,
        padding: 0
      },
      positionOptions,
      showToggle: false
    }
  },
  created() {
    this.configForm = {
      ...this.config
    }
  },
  filters: {
    filterType(val, index) {
      if (val === 'line') {
        return `【${index + 1}】折线图`
      }
      if (val === 'bar') {
        return `【${index + 1}】柱状图`
      }
      return `【${index + 1}】`;
    }
  },
  methods: {
    /**
     * @desc: 切换显示
     */
    onToggle() {
      this.showToggle = !this.showToggle;
    },
    /**
     * @desc: 提交
     */
    onEmit() {
      this.$emit('update', {
        index: this.index,
        config: this.configForm
      });
    }
  }
}
</script>

<style lang="less" scoped>
.series-label-item{
  width: 100%;
  border: 1px solid #f2f3f5;
  border-radius: 6px;
  box-sizing: border-box;
  padding: 5px;
  margin-bottom: 10px;
  .header{
    display: flex;
    padding-bottom: 5px;
    .title{
      flex: 1;
      font-size: 14px;
      line-height: 20px;
      box-sizing: border-box;
      padding-right: 10px;
      text-align: left;
    }
    a{
      color: @theme;
      font-size: 14px;
      line-height: 20px;
    }
  }
  .el-form{
    border-top: 1px solid #f2f3f5;
    padding-top: 5px;
    .el-form-item{
      margin-bottom: 10px;
    }
  }
}
</style>