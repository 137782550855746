import { render, staticRenderFns } from "./SeriesLabelItem.vue?vue&type=template&id=ba85ccda&scoped=true&"
import script from "./SeriesLabelItem.vue?vue&type=script&lang=js&"
export * from "./SeriesLabelItem.vue?vue&type=script&lang=js&"
import style0 from "./SeriesLabelItem.vue?vue&type=style&index=0&id=ba85ccda&prod&lang=less&scoped=true&"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "ba85ccda",
  null
  
)

export default component.exports