import { render, staticRenderFns } from "./SetXData.vue?vue&type=template&id=6dad1489&scoped=true&"
import script from "./SetXData.vue?vue&type=script&lang=js&"
export * from "./SetXData.vue?vue&type=script&lang=js&"
import style0 from "./SetXData.vue?vue&type=style&index=0&id=6dad1489&prod&lang=less&scoped=true&"


/* normalize component */
import normalizer from "!../../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "6dad1489",
  null
  
)

export default component.exports