<!--
 * @Description: 文本样式
 * @Author: luocheng
 * @Date: 2022-01-11 10:05:04
 * @LastEditors: luocheng
 * @LastEditTime: 2022-01-12 15:10:58
-->
<template>
	<div
		class="base-text-style"
		v-if="bindVal"
	>
    <header class="header">
			<span class="line"></span>
			<p class="title">{{ field }}</p>
			<el-button
				type="text"
				style="color:#409EFF"
				size="mini"
				@click="onToggle"
			>
				{{ isShow ? '收起' : '展开' }}
			</el-button>
		</header>
    <article v-show="isShow">
      <section
        v-for="(val, key) in bindVal"
        :key="key"
      >
        <template v-if="(typeof val) !== 'object' && transcode.title.textStyle[key]">
         <component
            :is="'Base' + transcode.title.textStyle[key].type"
            v-model="bindVal[key]"
            :field="transcode.title.textStyle[key].label"
            :options="transcode.title.textStyle[key].type === 'Select' ? transcode.title.textStyle[key].options : []"
          ></component>
        </template>
      </section>
    </article>
	</div>
</template>

<script>
import baseMixin from './base';
import BaseString from './BaseString.vue';
import BaseSwitch from './BaseSwitch.vue';
import BaseNumber from './BaseNumber.vue';
import BaseColor from './BaseColor.vue';
import BaseSelect from './BaseSelect.vue';
import transcode from '../../transcode';

export default {
	name: 'BaseTextStyle',
	mixins: [baseMixin],
  props: {
    value: {
      type: [Object, null],
      required: true,
      default: () => null
    }
  },
	components: {
		BaseString,
		BaseSwitch,
		BaseNumber,
    BaseColor,
    BaseSelect
	},
  data() {
    return {
      isShow: false,
      transcode
    }
  },
  methods: {
    /**
		 * @desc: 切换显示
		 */
		onToggle() {
			this.isShow = !this.isShow;
		}
  }
};
</script>

<style lang="less" scoped>
.base-text-style {
	width: 100%;
  .header {
		display: flex;
		.line {
			height: 12px;
			width: 3px;
			border-radius: 1px;
			background: @theme;
			margin-top: 8px;
			margin-right: 5px;
		}
		.title {
			flex: 1;
			line-height: 28px;
			overflow: hidden;
			text-overflow: ellipsis;
			white-space: nowrap;
			text-align: left;
			font-size: 14px;
		}
		.el-button {
			padding-right: 10px;
		}
	}
}
</style>