<!--
 * @Description: 图表类型
 * @Author: luocheng
 * @Date: 2022-01-05 15:10:09
 * @LastEditors: 朱廷果 1028509503@qq.com
 * @LastEditTime: 2023-01-12 09:14:32
-->
<template>
	<div class="chart-types">
		<ul class="list">
			<li
				class="item"
				v-for="chart in chartList"
				:key="chart.key"
        @click="onSelect(chart)"
        :class="{
          'active': chartType === chart.key
        }"
			>
				<i :class="['iconfont', chart.icon]"></i>
				<span>{{ chart.name }}</span>
			</li>
		</ul>
	</div>
</template>

<script>
import { mapState } from 'vuex'
import { getComponentId } from '@/utils/tools';
import {configObject} from '../../config';
import { deepClone } from '@topology/core';
import { chartList } from '@/chart-components/chartTypes.js';

export default {
	name: 'ChartTypes',
  data() {
    return {
      chartList,
      // 图表类型
      chartType: ''
    }
  },
  computed: {
    ...mapState({
      chartData: state => state.chart.chartData || null,
    })
  },
  watch: {
    chartData() {
      if (!this.chartData || !this.chartData.type) {
        this.chartType = ''
      }
    }
  },
  created() {
    this.chartType = this.chartData?.type || '';
  },
  methods: {
    /**
     * @desc: 选择类型
     * @param {String} val 类型
     */
    onSelect(val) {
      if (this.chartData.type && val.key === this.chartData.type) return;
      if (this.chartData.type) {
        this.$confirm('切换类型将导致配置项重置，是否切换？').then(() => {
          this.setChartType(val);
        }).catch(() => {});
      } else {
        this.setChartType(val);
      }
    },
    /**
     * @desc: 设置图表类型初始化配置项
     * @param {Object} data 图表配置项
     */
    setChartType(data) {
      this.chartType = data.key;
      const config = configObject[this.chartType];
      const component = this.chartList.find(ele => ele.key === this.chartType).component;
      this.$store.commit('setChartData', {
        ...deepClone(config),
        type: this.chartType,
        component,
        id: getComponentId(component)
      })
    }
  }
};
</script>

<style lang="less" scoped>
.chart-types {
	width: 100%;
	height: auto;
	.list {
		width: 100%;
		display: flex;
		flex-wrap: wrap;
		justify-content: flex-start;
		.item {
			padding: 0 5px 5px;
			box-sizing: border-box;
			border-radius: 4px;
			width: 25%;
			cursor: pointer;
			margin-bottom: 5px;
			display: flex;
			flex-direction: column;
      align-items: center;
			.iconfont {
				font-size: 16px;
			}
			span {
				display: block;
				font-size: 12px;
				line-height: 12px;
				text-align: center;
				overflow: hidden;
				text-overflow: ellipsis;
				white-space: nowrap;
			}
			&:hover {
				background: rgb(179, 216, 255);
				color: #fff;
			}
			&.active {
				background: rgb(179, 216, 255);
				color: #fff;
			}
		}
	}
}
</style>