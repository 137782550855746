<!--
 * @Description: 自定义配置数据
 * @Author: luocheng
 * @Date: 2022-06-22 10:30:03
 * @LastEditors: luocheng
 * @LastEditTime: 2022-06-22 16:27:46
-->
<template>
  <div class="custom-source">
    <!-- 横坐标 -->
    <SetXData v-model="config.xDataConfig"></SetXData>
    <!-- 图例说明 -->
    <LegendConfig v-model="config.legendConfig"></LegendConfig>
    <!-- 数据绑定 列表取第一条，详情就直接取 -->
    <BindConfig v-model="config.bindConfig"
      :xDataConfig="config.xDataConfig"
      :legendConfig="config.legendConfig"
    ></BindConfig>
    <footer class="actions">
      <el-button type="primary" @click="onSubmit">提交</el-button>
    </footer>
  </div>
</template>

<script>
import SetXData from './SetXData';
import LegendConfig from './LegendData';
import BindConfig from './BindConfig';
import { mapState } from 'vuex';

export default {
  name: 'CustomSource',
  components: {
    SetXData,
    LegendConfig,
    BindConfig
  },
  data() {
    return {
      config: {
        xDataConfig: [],
        legendConfig: [],
        bindConfig: []
      }
    }
  },
  computed: {
    ...mapState({
			chartData: (state) => state.chart.chartData || null
		}),
  },
  created() {
    if (this.chartData?.customConfig) {
      this.config = this.chartData?.customConfig;
    }
  },
  // watch: {
  //   config: {
  //     handler() {
  //       console.log(this.config, '----基础peizhi-------')
  //     },
  //     deep: true,
  //     immediate: true
  //   }
  // },
  methods: {
    /**
     * @desc: 保存配置
     */
    onSubmit() {
      this.$store.commit('setChartData', {
				...this.chartData,
				customConfig: this.config
			});
    }
  }
}
</script>

<style lang="less" scoped>
.custom-source{
  width: 100%;
  .actions{
    padding-top: 10px;
    display: flex;
    .el-button{
      flex: 1;
    }
  }
}
</style>