<!--
 * @Description: 雷达图标志位配置
 * @Author: luocheng
 * @Date: 2022-01-14 09:35:40
 * @LastEditors: luocheng
 * @LastEditTime: 2022-05-05 14:20:02
-->
<template>
	<div class="radar-indicator">
		<header class="indicator-header">
			<p class="label">{{ field }}</p>
      <a href="javascript:;" class="reset header-btn" @click="getData">重置</a>
      <a href="javascript:;" class="submit header-btn" @click="updateVal">提交</a>
		</header>
		<ul class="indicator-list">
			<li
				class="indicator-item"
				v-for="(indicator, index) in bindVal"
				:key="index"
			>
				<div class="row">
					<span class="label">指标：</span>
					<el-input
						v-model="indicator.label"
						size="mini"
						placeholder="指标"
						disabled
					></el-input>
				</div>
				<div class="row">
					<span class="label">最大值：</span>
					<el-input-number
						v-model="indicator.value"
						size="mini"
						placeholder="请输入最大值"
					></el-input-number>
				</div>
			</li>
		</ul>
	</div>
</template>

<script>
/* eslint-disable */
import { dataInterface } from '@/apis/data';
import baseMixin from './base';
import { InputNumber } from 'element-ui';

export default {
	name: 'RadarIndicator',
	mixins: [baseMixin],
	components: {
		'el-input-number': InputNumber
	},
	data() {
		return {};
	},
	methods: {
		/**
		 * @desc: 获取维度数据
		 */
		getData() {
			dataInterface({
				__method_name__: 'globalFunctionCall',
				typeName: 'PublicFunc',
				type: 'value',
				funcName: 'CommonAnalyse',
				payload: {
					page_uuid: this.$route.query.pageUUID
				}
			})
				.then((res) => {
					if (res && res.status === 200 && res.data && res.data.code === 200) {
            const { xData = [] } = res.data.data[0];
            this.bindVal = [];
            xData.forEach(label => {
              this.bindVal.push({
                label,
                value: 1000
              })
            });
					}
				})
				.catch((err) => {
					console.log(err);
				});
		}
	}
};
</script>

<style lang="less" scoped>
.radar-indicator {
	width: 100%;
	.label {
		line-height: 28px;
		font-size: 14px;
		overflow: hidden;
		text-overflow: ellipsis;
		white-space: nowrap;
		margin-right: 10px;
    text-align: left;
	}
	.indicator-header {
		display: flex;
		.label {
			flex: 1;
		  line-height: 20px;
		}
    .header-btn{
      line-height: 20px;
      font-size: 14px;
      box-sizing: border-box;
      padding: 0 5px;
      &.reset{
        color: #E6A23C;
      }
      &.submit{
        color: #409EFF;
      }
    }
	}
	.indicator-list {
		width: 100%;
    box-sizing: border-box;
    padding-left: 3em;
		.indicator-item {
			width: 100%;
      border-bottom: 1px solid #f2f3f5;
			.row {
				display: flex;
        padding-bottom: 5px;
				.label {
          min-width: 80px;
          max-width: 120px;
          font-size: 12px;
          margin-right: 6px;
				}
			}
		}
	}
}
</style>
