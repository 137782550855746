/*
 * @Description: 翻牌器
 * @Author: luocheng
 * @Date: 2022-01-13 13:52:04
 * @LastEditors: luocheng
 * @LastEditTime: 2022-01-13 15:13:33
 */
import baseTrans from './baseTrans';

export default {
  ...baseTrans,
  width: {
    name: '宽度',
    type: 'String'
  },
  theme: {
    type: 'Select',
    name: '布局风格',
    options: [
      {
        label: '常规',
        value: 'normal'
      },
      {
        label: '左右',
        value: 'left-right'
      },
      {
        label: '值在下',
        value: 'value-bottom'
      }
    ]
  },
  showTitle: {
    name: '显示标题',
    type: 'Switch'
  },
  title: {
    name: '标题文本',
    type: 'String'
  },
  titleSize: {
    name: '标题字号',
    type: 'Number'
  },
  titleLineHeight: {
    name: '标题行高',
    type: 'Number'
  },
  titleColor: {
    name: '标题颜色',
    type: 'Color'
  },
  titleAlign: {
    name: '标题文本位置',
    type: 'Select',
    options: [
      {
        label: '居中',
        value: 'center'
      },
      {
        label: '居左',
        value: 'left'
      },
      {
        label: '居右',
        value: 'right'
      }
    ]
  },
  showLabel: {
    type: 'Switch',
    name: '显示说明'
  },
  labelSize: {
    name: '说明字号',
    type: 'Number'
  },
  labelLineHeight: {
    name: '说明行高',
    type: 'Number'
  },
  labelColor: {
    name: '说明颜色',
    type: 'Color'
  },
  labelAlign: {
    name: '说明位置',
    type: 'Select',
    options: [
      {
        label: '居中',
        value: 'center'
      },
      {
        label: '居左',
        value: 'left'
      },
      {
        label: '居右',
        value: 'right'
      }
    ]
  },
  valueSize: {
    name: '值字号',
    type: 'Number'
  },
  valueColor: {
    name: '值颜色',
    type: 'Color'
  },
  valueAlign: {
    name: '值文本位置',
    type: 'Select',
    options: [
      {
        label: '居中',
        value: 'center'
      },
      {
        label: '居左',
        value: 'left'
      },
      {
        label: '居右',
        value: 'right'
      }
    ]
  },
  valueLineHeight: {
    name: '值行高',
    type: 'Number'
  },
  unitSize: {
    name: '单位字号',
    type: 'Number'
  },
  unitColor: {
    name: '单位颜色',
    type: 'Color'
  },
  unitLineHeight: {
    name: '单位行高',
    type: 'Number'
  },
  moneyType: {
    name: '货币符号',
    type: 'Select',
    options: [
      {
        label: '人民币',
        value: '￥'
      },
      {
        label: '美元',
        value: '$'
      },
      {
        label: '欧元',
        value: '€'
      },
      {
        label: '俄罗斯卢布',
        value: '₽'
      },
      {
        label: '英镑',
        value: '￡'
      },
      {
        label: '法郎',
        value: '₣'
      },
      {
        label: '韩元',
        value: '₩'
      },
      {
        label: '日元',
        value: 'J￥'
      },
      {
        label: '泰铢',
        value: '฿'
      },
      {
        label: '印度卢比',
        value: 'Rs.'
      },
      {
        label: '港币',
        value: 'HK$'
      }
    ]
  }
}