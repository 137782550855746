<!--
 * @Description: 横坐标
 * @Author: luocheng
 * @Date: 2022-06-22 11:50:42
 * @LastEditors: luocheng
 * @LastEditTime: 2022-06-22 13:49:04
-->
<template>
  <div class="x-item">
    <el-input v-model="xName" placeholder="请输入横坐标" clearable @input="onUpdate"></el-input>
    <i class="el-icon-remove" @click="onRemove"></i>
  </div>
</template>

<script>
export default {
  name: 'xItem',
  props: {
    config: {
      type: String,
      required: false
    },
    index: {
      type: Number,
      required: true,
      default: 0
    }
  },
  data() {
    return {
      xName: ''
    }
  },
  created() {
    this.xName = this.config || '';
  },
  methods: {
    onUpdate() {
      this.$emit('update', {
        index: this.index,
        data: this.xName
      })
    },
    onRemove() {
      this.$emit('remove', this.index)
    }
  }
}
</script>

<style lang="less" scoped>
.x-item{
  display: flex;
  padding-bottom: 10px;
  .el-input{
    flex: 1;
  }
  i{
    box-sizing: border-box;
    padding: 0 5px;
    line-height: 32px;
    font-size: 16px;
    color: @dangerColor;
  }
}
</style>