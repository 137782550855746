/*
 * @Description: 漏斗图
 * @Author: luocheng
 * @Date: 2022-01-13 17:54:36
 * @LastEditors: luocheng
 * @LastEditTime: 2022-01-13 17:54:37
 */
import baseTrans from './baseTrans';


export default {
  ...baseTrans,
  colorRange: {
    type: 'ColorRange',
    name: '颜色'
  }
}