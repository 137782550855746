<!--
 * @Description: 图表编辑器
 * @Author: luocheng
 * @Date: 2022-01-05 14:57:28
 * @LastEditors: luocheng
 * @LastEditTime: 2022-04-29 16:30:46
-->
<template>
	<div
		class="chart-editor"
		:style="{
			width: canvasWidth + 'px',
			height: canvasHeight + 'px',
			backgroundColor: canvasStyle.backgroundColor
		}"
	>
		<el-empty description="请选择图表类型" v-if="!chartData || !chartData.type"></el-empty>
		<ChartBox v-else></ChartBox>
	</div>
</template>

<script>
import { Empty } from 'element-ui';
import { mapState } from 'vuex';
import ChartBox from './ChartBox';

export default {
	name: 'ChartEditor',
	components: {
		ChartBox,
		'el-empty': Empty
	},
	computed: {
		...mapState({
			canvasStyle: state => state.canvasStyle || {},
      chartData: state => state.chart.chartData || {}
    }),
		// 画布宽度
		canvasWidth() {
			if (isNaN(this.canvasStyle.width) || !this.canvasStyle.scale) {
				return this.canvasStyle.width;
			}
			return (this.canvasStyle.width * parseInt(this.canvasStyle.scale)) / 100;
		},
		// 画布高度
		canvasHeight() {
			if (isNaN(this.canvasStyle.height) || !this.canvasStyle.scale) {
				return this.canvasStyle.height;
			}
			return (this.canvasStyle.height * parseInt(this.canvasStyle.scale)) / 100;
		}
	}
};
</script>

<style lang="less" scoped>
.chart-editor {
	height: 100%;
	width: 100%;
	background: #fff;
	margin: 0 auto;
	// position: absolute;
	// left: 50%;
	// top: 50%;
	// transform: translate(-50%, -50%);
}
</style>