<!--
 * @Description: 横向滚动条滚动
 * @Author: luocheng
 * @Date: 2022-05-07 10:48:47
 * @LastEditors: luocheng
 * @LastEditTime: 2022-05-07 15:31:09
-->
<template>
  <div class="config-completion">
    <Item v-for="(item, index) in configList"
      :key="index"
      :index="index"
      :config="item"
      @update="onUpdate"
      @remove="onRemove"
      :fieldList="fieldList">
    </Item>
    <footer class="footer">
      <el-button type="success" size="mini" @click="onPlus">新增</el-button>
    </footer>
  </div>
</template>

<script>
import Item from './Item';

export default {
  name: 'ConfigCompletion',
  components: {
    Item
  },
  props: {
    value: {
      type: Array,
      required: true,
      default: () => []
    },
    fieldList: {
      type: Array,
      default: () => [],
      required: true
    }
  },
  data() {
    return {
      configList: []
    }
  },
  created() {
    this.configList = this.value || []
  },
  methods: {
    /**
     * @desc: 更新配置
     * @param {Number} index 下标
     * @param {Object} config 配置
     */
    onUpdate({ index, config }) {
      this.configList.splice(index, 1, {
        ...config
      });
      this.onEmit();
    },
    /**
     * @desc: 移除
     * @param {Number} index 下标
     */
    onRemove(index) {
      this.configList.splice(index, 1);
    },
    /**
     * @desc: 新增 
     */
    onPlus() {
      this.configList.push({
        complete: '',
        total: '',
        completeText: ''
      });
      this.onEmit();
    },
    /**
     * @desc: 提交
     */
    onEmit() {
      this.$emit('input', this.configList);
    }
  }
}
</script>

<style lang="less" scoped>
.config-completion{
  .footer{
    padding-bottom: 10px;
  }
}
</style>