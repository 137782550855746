<!--
 * @Description: 模拟组织架构信息
 * @Author: luocheng
 * @Date: 2021-12-15 11:45:42
 * @LastEditors: luocheng
 * @LastEditTime: 2022-10-09 09:44:24
-->
<template>
	<div class="mock-archi-container">
		<div class="mock-archi">
			<section class="infos" @click="onArchi">
				<span class="name">{{ userInfo.name || '无模拟用户' }}</span>
				<template v-if="targetArchi">
					<span class="archi-type" v-if="targetArchi.archiType === 'company'">公司</span>
					<span class="archi-type" v-else-if="targetArchi.archiType === 'project'">项目</span>
					<span class="name">{{ targetArchi.name }}</span>
				</template>
				<span v-else class="no-data">暂无架构</span>
				<div class="select-tips">切换架构</div>
			</section>
			<!-- 修改架构信息 -->
			<el-drawer
				title="架构信息模拟"
				:visible.sync="showSelect"
				:direction="'ltr'"
				:destroy-on-close="true"
				size="400px"
			>
				<article class="archi-box">
					<article class="archi-main">
						<!-- 登录信息 -->
						<section class="item login-info">
							<div class="header-title">
								<span class="line"></span>
								<h3>当前登录信息</h3>
							</div>
							<div class="info-item" v-if="userInfo">
								<span class="label">用户名：</span>
								<p class="value">{{ userInfo.name }}</p>
							</div>
							<el-button type="text" @click="onToggleUser">{{ showLogin ? '收起' : '切换用户' }}</el-button>

							<div v-show="showLogin">
								<el-button class="w-full" type="warning" style="margin-bottom: 20px" @click="insertToken"
									>输入token进行模拟</el-button
								>
								<el-form :model="loginForm" ref="loginForm" :rules="loginRules">
									<el-form-item prop="userName">
										<el-input v-model="loginForm.userName" placeholder="请输入用户名" clearable></el-input>
									</el-form-item>
									<el-form-item props="password">
										<el-input
											v-model="loginForm.password"
											type="password"
											placeholder="请输入密码"
											clearable
											@keyup.enter.native="onLogin"
											show-password
										></el-input>
									</el-form-item>
									<el-form-item>
										<el-button class="w-full" size="mini" type="primary" @click="onLogin">确认切换 </el-button>
									</el-form-item>
								</el-form>
							</div>
						</section>
						<!-- 架构信息 -->
						<section class="item login-info">
							<div class="header-title">
								<span class="line"></span>
								<h3>当前登录架构</h3>
							</div>
							<template v-if="targetArchi">
								<div class="info-item">
									<span class="label">名称：</span>
									<p class="value">{{ targetArchi.name }}</p>
								</div>
								<div class="info-item">
									<span class="label">类型：</span>
									<p class="value" v-if="targetArchi.archiType === 'company'">公司</p>
									<p class="value" v-else-if="targetArchi.archiType === 'project'">项目</p>
									<p class="value" v-else>其他</p>
								</div>
							</template>
							<div class="no-data info-item" v-else>未选择架构</div>
							<el-button type="text" @click="onToggleArchi">
								{{ showArchi ? '收起' : '切换架构' }}
							</el-button>
							<div class="select-archi" v-show="showArchi">
								<el-form :model="archiForm" ref="archiForm" :rules="archiRules">
									<el-form-item prop="type">
										<el-radio-group v-model="archiForm.type" @change="onType">
											<el-radio label="company">公司</el-radio>
											<el-radio label="project">项目</el-radio>
										</el-radio-group>
									</el-form-item>
									<el-form-item prop="id">
										<el-select class="w-full" v-model="archiForm.id" placeholder="请选择架构" clearable filterable>
											<el-option
												v-for="item in archiList"
												:key="item.id"
												:label="item.name"
												:value="item.id"
											></el-option>
										</el-select>
									</el-form-item>
									<el-form-item>
										<el-button class="w-full" type="primary" @click="onSelectArchi">确认切换 </el-button>
									</el-form-item>
								</el-form>
							</div>
						</section>
					</article>
					<footer class="footer">
						<el-button type="primary" @click="onClose(false)">仅保存</el-button>
						<el-button type="warning" @click="onClose(true)">保存并刷新</el-button>
					</footer>
				</article>
			</el-drawer>
		</div>
	</div>
</template>

<script>
import { dataInterface } from '@/apis/data';
import { setToken } from '@/utils/tools';
import objects from '@/api/objects';
import eventBus from '@/plugins/eventBus';

export default {
	name: 'MockArchi',
	data() {
		return {
			userInfo: null,
			targetArchi: null,
			showSelect: false,
			showLogin: false,
			// 登录
			loginForm: {
				userName: '',
				password: ''
			},
			loginRules: {
				userName: [
					{ required: true, massage: '请输入用户名', trigger: 'blur' },
					{ min: 2, max: 16, message: '长度在 2 到 16 个字符', trigger: 'blur' }
				],
				password: [
					{ required: true, massage: '用户名请输入密码', trigger: 'blur' },
					{
						min: 6,
						max: 20,
						message: '密码长度在 6 到 20 个字符',
						trigger: 'blur'
					}
				]
			},
			// 架构
			showArchi: false,
			companyList: [],
			projectList: [],
			archiForm: {
				type: 'company',
				id: ''
			},
			archiRules: {
				type: [{ required: true, massage: '请选择类型', trigger: 'change' }],
				id: [{ required: true, massage: '请选择架构', trigger: 'change' }]
			},
			// 服务器列表
			serverList: [],
			showServer: false
			// targetServer: {
			//   metadatatoken: 'aba62ca1-c2ff-42af-9d3a-bbe5b02f7b06',
			//   name: 'laravel9测试服务器',
			//   originURl: 'https://dwh_api.bimcc.net'
			// }
		};
	},
	created() {
		this.getInfos();
		this.setMockUser();
		if (!sessionStorage.getItem('targetMockArchis') || sessionStorage.getItem('targetMockArchis') === 'undefined') {
			//this.getArchiList();
		} else {
			const archiDatas = JSON.parse(sessionStorage.getItem('targetMockArchis'));
			this.companyList = archiDatas.company;
			this.projectList = archiDatas.project;
		}
		// 架构信息
		// try {
		//   this.serverList = JSON.parse(localStorage.getItem('serverList'));
		//   const targetServer = localStorage.getItem('projectInfo');
		//   this.targetServer = targetServer ? JSON.parse(targetServer) : {
		//     metadatatoken: "aba62ca1-c2ff-42af-9d3a-bbe5b02f7b06",
		//     name: "laravel9测试服务器",
		//     originURl: "https:/dwh_api.bimcc.net"
		//   }
		// } catch (e) {
		//   console.log(e);
		// }
	},
	computed: {
		archiList() {
			if (this.archiForm.type === 'company') {
				return this.companyList || [];
			}
			if (this.archiForm.type === 'project') {
				return this.projectList || [];
			}
			return [];
		}
	},
	methods: {
		/**
		 * @desc: 获取信息
		 */
		getInfos() {
			this.userInfo = this.$GetUserInfo();
			this.targetArchi = this.$GetTargetArchi();
		},

		/**
		 * @desc: 设置数仓模拟信息
		 */
		setMockUser() {
			let mockUserInfo = {
				user_id: this.userInfo.id,
				user_name: this.userInfo.name,
				token: this.userInfo.token,
				archi_name: this.targetArchi.name,
				archi_type: this.targetArchi.data_id
			};
			this.$store.commit('setMockUser', mockUserInfo);
		},

		/**
		 * @desc: 显示用户切换
		 */
		onToggleUser() {
			this.showLogin = !this.showLogin;
		},
		/**
		 * @desc: 切换用户信息
		 */
		onLogin() {
			this.$refs.loginForm.validate((valid) => {
				if (!valid) return;
				// 账号密码
				const payload = {
					password: this.loginForm.password,
					name: this.loginForm.userName,
				};
				const loading = this.$loading();
				dataInterface({
					__method_name__: 'globalFunctionCall',
					typeName: 'LoginFuncClass',
					type: 'behavior',
					funcName: 'LoginBehavior',
					payload,
					transcode: 0
				})
					.then((res) => {
						console.log(res, '-1--11111111-11')
						loading.close();
						if (res.data.code == 200) {
							this.$message.success('已切换!');
							const userInfo = res.data.data;
							localStorage.setItem('userInfo', JSON.stringify(userInfo));
							setToken(userInfo.token || '');
							localStorage.setItem('devUserInfo', JSON.stringify(userInfo));
							this.showLogin = false;
							this.getInfos();
							this.setMockUser();
							this.getArchiList();
						}
					})
					.catch((err) => {
						console.log(err);
						loading.close();
					});
			});
		},

		/**
		 * @desc: 选择架构
		 */
		onToggleArchi() {
			this.showArchi = !this.showArchi;
		},
		/**
		 * @desc: 切换类型
		 */
		onType() {
			this.archiForm.id = '';
		},
		/**
		 * @desc: 切换组织架构
		 */
		onSelectArchi() {
			this.$refs.archiForm.validate((valid) => {
				if (!valid) return;
				this.$loading();
				dataInterface({
					__method_name__: 'globalFunctionCall',
					typeName: 'LoginFuncClass',
					type: 'behavior',
					funcName: 'ToggleArchBehavior',
					payload: {
						archi_id: this.archiForm.id,
						archi_table: this.archiForm.type === 'company' ? 'org_companys' : 'org_projects'
					}
				})
					.then((res) => {
						if (!res || res.status !== 200) {
							return false;
						}
						localStorage.setItem(
							'targetArchi',
							JSON.stringify({
								archiType: this.archiForm.type,
								archiId: res.data.data.archi_id,
								...res.data.data,
								id: res.data.data.data_id,
								company_id: res.data.data.data.company_id || ''
							})
						);
						localStorage.setItem('userRoles', res.data.data.permissions || '');
						this.$loading().close();
						this.$message.success('已切换!');
						this.getInfos();
						this.setMockUser();
					})
					.catch((err) => {
						console.log(err);
						this.$loading().close();
					});
			});
		},
		/**
		 * @desc: 获取可选架构
		 */
		getArchiList() {
			this.$loading();
			// , this.targetServer.originURl + '/api/mapi'
			dataInterface({
				__method_name__: 'globalFunctionCall',
				typeName: 'Archi',
				type: 'value',
				funcName: 'AllArchi'
			})
				.then((res) => {
					if (!res || res.status !== 200) return false;
					const archiData = res.data.data;
					sessionStorage.setItem('targetMockArchis', JSON.stringify(archiData));
					this.companyList = archiData.company || [];
					this.projectList = archiData.project || [];
					this.$loading().close();
				})
				.catch((err) => {
					console.log(err);
					this.$loading().close();
				});
		},
		/**
		 * @desc: 切换登录及架构信息
		 */
		onArchi() {
			this.showSelect = true;
		},
    /**
     * @desc: 完成配置
     */
		onClose(reload = false) {
			this.loginForm = {
				userName: '',
				password: ''
			};
			this.archiForm = {
				type: 'company',
				id: ''
			};
			this.showSelect = false;
      if (reload) {
        window.location.reload();
      }
		},
		/**
		 * @desc: 切换服务器
		 */
		onToggleServer() {
			this.showServer = !this.showServer;
		},
		/**
		 * @desc: 确认切换
		 */
		onConfigServer() {
			const result = this.serverList?.find((ele) => ele.name === this.targetServer.name);
			localStorage.setItem('targetServer', JSON.stringify(result));
			this.$message.success('已切换!');
		},

		/**
		 * @description: 输入token模拟
		 */
		insertToken() {
			this.$prompt('请输入token值进行模拟').then((res) => {
				if (res.action !== 'confirm') {
					return;
				}
				const token = res.value;
				let loading = this.$loading();
				// 查询token值是否存在
				objects.mapi
					.common({
						object_uuid: '84d6a0d3-db15-479f-8a18-a1539156bf59',
						view_uuid: 'view6188d4aaf085c',
						__method_name__: 'dataList',
						transcode: 0,
						search: [
							{
								code: 'token',
								ruleType: 'eq',
								value: token
							}
						]
					})
					.then((res) => {
						loading.close();
						console.log(res, '--000----', res.data);
						if (res.data.code == 200 && res.data.data.data.length > 0) {
							let data = res.data.data.data[0];
							let mockUserInfo = {
								archi_type: data.archi_table + '-' + data.archi_id,
								user_id: data.user_id,
								user_name: data.token_user_name,
								archi_name: data.four_archi_name,
								token: data.token
							};
							this.$store.commit('setMockUser', mockUserInfo);

							localStorage.setItem(
								'userInfo',
								JSON.stringify({
									id: data.user_id,
									name: data.token_user_name,
									token: data.token
								})
							);
							setToken(mockUserInfo.token);

							dataInterface({
								__method_name__: 'globalFunctionCall',
								typeName: 'LoginFuncClass',
								type: 'behavior',
								funcName: 'ToggleArchBehavior',
								payload: {
									archi_id: mockUserInfo.archi_type,
									archi_table: mockUserInfo.archi_table
								}
							}).then((res) => {
								if (!res || res.status !== 200) {
									return false;
								}
								localStorage.setItem(
									'targetArchi',
									JSON.stringify({
										archiType: this.archiForm.type,
										archiId: res.data.data.archi_id,
										...res.data.data,
										id: res.data.data.data_id,
										company_id: res.data.data.data.company_id || ''
									})
								);
								localStorage.setItem('userRoles', res.data.data.permissions || '');

								this.getInfos();
								this.getArchiList();
								this.$message.success('模拟成功');
							});
						} else {
							this.$message.error('未找到该token的有效数据！');
						}
					})
					.catch(() => {
						loading.close();
						this.$message.error('未找到该token的有效数据！');
					});
			});
		}
	},
	mounted() {
		eventBus.$on('showChangeArchi', () => {
			console.log(this.showSelect);
			this.onArchi();
		});
	}
};
</script>

<style lang="less" scoped>
.mock-archi-container {
	display: flex;
	justify-content: center;
	align-items: center;
	height: 100%;
	.mock-archi {
		display: flex;
		width: auto;
		box-sizing: border-box;
		padding: 0 5px;
		border-radius: 4px;
		border: 1px solid #f2f3f5;
		cursor: pointer;
		position: relative;
		height: 32px;
		overflow: hidden;

		.infos {
			display: flex;
			justify-content: center;
			align-items: center;
			.server {
				// font-weight: bold;
				color: @warnColor;
			}
		}

		&:hover {
			.select-tips {
				display: block;
				opacity: 1;
				top: 0;
			}
		}

		.select-tips {
			opacity: 0;
			position: absolute;
			top: 32px;
			left: 0;
			height: 100%;
			width: 100%;
			background: rgba(0, 0, 0, 0.7);
			line-height: 32px;
			text-align: center;
			color: @minorColor;
			font-size: 14px;
			transition: all 0.3s;
		}

		&:hover {
			border-color: @theme;
		}

		span {
			line-height: 32px;
			margin-right: 5px;
			font-size: 12px;

			&:last-of-type {
				margin: 0;
			}

			&.no-data {
				color: @warnColor;
			}
		}

		.archi-type {
			color: @warnColor;
		}

		.archi-box {
			box-sizing: border-box;
			height: 100%;
			width: 100%;
			display: flex;
			flex-direction: column;

			.archi-main {
				width: 100%;
				flex: 1;
				overflow: hidden;
				overflow-y: auto;
				box-sizing: border-box;
				padding: 10px 20px;

				.item {
					border-bottom: 1px solid #f2f3f5;

					&:last-of-type {
						margin-top: 15px;
						border: none;
					}

					.header-title {
						display: flex;
						border-bottom: 1px solid #f2f3f5;
						padding-bottom: 10px;
						color: #606266 !important;
						line-height: 14px !important;

						.line {
							margin-right: 5px;
							width: 2px;
							height: 13px;
							background: @theme;
							border-radius: 1px;
						}

						h3 {
							font-size: 14px;
						}
					}

					.info-item {
						display: flex;
						margin-top: 10px;
						padding: 10px 0;
						width: 100%;
						color: #606266 !important;

						&.no-data {
							display: block;
							width: 100%;
							text-align: center;
							color: @dangerColor;
							font-weight: bold;
							margin: 15px 0;
						}

						.label {
							line-height: 20px;
							font-size: 14px;
						}

						.value {
							flex: 1;
							font-size: 14px;
							line-height: 20px;
						}
					}
				}
			}

			.footer {
				padding: 15px 0;
				border-top: 1px solid #f2f3f5;
			}
		}

		.server-info {
			margin-bottom: 10px;
		}
	}
}
</style>
