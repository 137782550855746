/*
 * @Description: 
 * @Author: luocheng
 * @Date: 2022-09-26 14:21:26
 * @LastEditors: luocheng
 * @LastEditTime: 2022-09-27 14:34:06
 */
import baseTrans from './baseTrans';


export default {
  ...baseTrans,
  radius: {
    type: 'String',
    name: '半径'
  },
  positionX: {
    type: 'String',
    name: 'X轴坐标（50%时在中间）'
  },
  positionY: {
    type: 'String',
    name: 'Y坐标(50%时在中间)'
  },
  backgroundColor: {
    type: 'Color',
    name: '背景颜色'
  },
  clockwise: {
    type: 'Switch',
    name: '顺时针'
  },
  axisShow: {
    type: 'Switch',
    name: '显示轴线'
  },
  roundCap: {
    type: 'Switch',
    name: '两端圆形'
  },
  lineStyleColor: {
    type: 'Color',
    name: '背景槽颜色'
  },
  lineStyleWidth: {
    type: 'Number',
    name: '背景槽宽度'
  },
  progress: {
    type: 'Switch',
    name: '显示进度条'
  },
  progressWidth: {
    type: 'Number',
    name: '进度条宽度'
  },
  split: {
    type: 'Switch',
    name: '显示分割线'
  },
  splitNumber: {
    type: 'Number',
    name: '分隔段数'
  },
  splitLength: {
    type: 'Number',
    name: '分割线长度'
  },
  splitDistance: {
    type: 'Number',
    name: '分割线与轴线距离'
  },
  splitColor: {
    type: 'Color',
    name: '分割线颜色'
  },
  splitWidth: {
    type: 'Number',
    name: '分割线宽度'
  },
  splitType: {
    type: 'Select',
    name: '分割线类型',
    options: [
      {
        label: '实线',
        value: 'solid'
      },
      {
        label: '虚线',
        value: 'dashed'
      },
      {
        label: '点',
        value: 'dotted'
      }
    ]
  },
  mainColor: {
    type: 'Color',
    name: '主色'
  },
  lessColor: {
    type: 'Color',
    name: '副色'
  },
  detailUnit: {
    type: 'String',
    name: '单位'
  },
  detailColor: {
    type: 'String',
    name: '文本颜色'
  },
  detailShadow: {
    type: 'Switch',
    name: '文本区域边框'
  },
  detailShadowColor: {
    type: 'Color',
    name: '边框阴影颜色'
  },
  detailShadowBlur:  {
    type: 'Number',
    name: '边框阴影宽度'
  },
  detailWidth: {
    type: 'Number',
    name: '文本宽度'
  },
  detailHeight: {
    type: 'Number',
    name: '文本高度'
  },
  detailFontSize: {
    type: 'Number',
    name: '文本字号'
  },
  detailLineHeight: {
    type: 'Number',
    name: '文本行高'
  },
  borderRadius: {
    type: 'Number',
    name: '文本区域圆角'
  },
  borderWidth: {
    type: 'Number',
    name: '文本边框颜色'
  },
  borderColor: {
    type: 'Color',
    name: '边框颜色'
  }
}