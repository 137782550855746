/*
 * @Description: 
 * @Author: luocheng
 * @Date: 2022-05-31 09:32:38
 * @LastEditors: luocheng
 * @LastEditTime: 2022-09-26 09:40:05
 */
export const chartList = [
  {
    key: 'bar',
    component: 'ChartBar',
    name: '柱状图',
    icon: 'icongongsi1'
  },
  {
    key: 'line',
    component: 'ChartLine',
    name: '折线图',
    icon: 'icongongsi1'
  },
  {
    key: 'barLine',
    component: 'ChartBarLine',
    name: '折柱混合',
    icon: 'icongongsi1'
  },
  {
    key: 'pie',
    name: '饼/环图',
    component: 'ChartPie',
    icon: 'icongongsi1'
  },
  {
    key: 'flop',
    name: '翻牌器',
    component: 'ChartFlop',
    icon: 'icongongsi1'
  },
  {
    key: 'radar',
    name: '雷达图',
    component: 'ChartRadar',
    icon: 'icongongsi1'
  },
  {
    key: 'funnel',
    name: '漏斗图',
    component: 'ChartFunnel',
    icon: 'icongongsi1'
  },
  {
    key: 'table',
    name: '数据表格',
    component: 'ChartTable',
    icon: 'icongongsi1'
  },
  {
    key: 'ring',
    name: '环图(总量)',
    component: 'ChartRing',
    icon: 'icongongsi1'
  },
  {
    key: 'completionRatio',
    name: '横向进度柱图',
    component: 'ChartCompletionRatio',
    icon: 'icongongsi1'
  },
  // {
  //   key: '3DPie',
  //   name: '3D饼图',
  //   component: 'Chart3DPie',
  //   icon: 'icongongsi1'
  // }
  {
    key: 'pointer',
    name: '仪表盘',
    component: 'ChartPointer',
    icon: 'icongongsi1'
  }
];