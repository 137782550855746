/*
 * @Description: 
 * @Author: luocheng
 * @Date: 2022-01-11 17:34:43
 * @LastEditors: luocheng
 * @LastEditTime: 2022-04-29 15:10:54
 */
import baseTrans from './baseTrans';

export default {
  ...baseTrans,
  showTitle: {
    type: 'Switch',
    name: '显示标题'
  },
  title: {
    type: 'String',
    name: '图形标题'
  },
  textColor: {
    type: 'Color',
    name: '文字颜色'
  },
  titleFontSize: {
    type: 'Number',
    name: '标题字号'
  },
  titleFontWeight: {
    type: 'Number',
    name: '标题字重'
  },
  showTooltip: {
    type: 'Switch',
    name: '显示提示框'
  },
  showLegend: {
    type: 'Switch',
    name: '显示图例'
  },
  legendTextColor: {
    type: 'Color',
    name: '图例文字颜色'
  },
  axisFontSize: {
    type: 'Number',
    name: 'XY轴字号'
  },
  axisTextColor: {
    type: 'Color',
    name: 'XY轴文字颜色'
  },
  xAxisText: {
    type: 'String',
    name: 'X轴单位'
  },
  xAxisTextFontSize: {
    type: 'Number',
    name: 'X轴单位字号'
  },
  xAxisTextColor: {
    type: 'Color',
    name: 'X轴单位颜色'
  },
  yAxisText: {
    type: 'String',
    name: 'Y轴单位'
  },
  yAxisTextFontSize: {
    type: 'Number',
    name: 'Y轴单位字号'
  },
  yAxisTextColor: {
    type: 'Color',
    name: 'Y轴单位颜色'
  },
  top: {
    type: 'Number',
    name: '图形上边距(百分比)'
  },
  bottom: {
    type: 'Number',
    name: '图形下边距(百分比)'
  },
  left: {
    type: 'Number',
    name: '图形左边距(百分比)'
  },
  right: {
    type: 'Number',
    name: '图形右边距(百分比)'
  },
  colorRange: {
    type: 'ColorRange',
    name: '柱体颜色'
  },
  lineColorRange: {
    type: 'ColorRange',
    name: '折线颜色'
  },
  barWidth: {
    type: 'Number',
    name: '柱体大小',
    options: {
      min: 10,
      max: 50
    }
  },
  modal: {
    type: 'SourceModal',
    name: '数据弹窗'
  },
  showLine: {
    type: 'Switch',
    name: '是否显示折线图'
  },
  showBarLabel: {
    type: 'Switch',
    name: '是否柱状图顶部数字'
  },
  barChart: {
    type: 'Switch',
    name: '转为条形图'
  },
  distinguishColor: {
    type: 'Switch',
    name: '区别主轴颜色'
  },
  XColorRange: {
    type: 'ColorRange',
    name: '主轴柱体颜色'
  },
  showDataZoom: {
    name: '显示缩放',
    type: 'Switch'
  },
  dataZoomBottom: {
    name: '下边距',
    type: 'String'
  },
  dataZoomStart: {
    name: '数据开始',
    type: 'Number'
  },
  dataZoomEnd: {
    name: '数据结束',
    type: 'Number'
  },
  useGradient: {
    name: '使用渐变',
    type: 'Switch'
  },
  gradientRange: {
    name: '渐变颜色数组',
    type: 'Color2Array'
  },
  usePolar: {
    name: '使用极坐标',
    type: 'Switch'
  },
  minInterval: {
    name: '刻度最小间隔',
    type: 'Number'
  }
}