<!--
 * @Description: 静态统计
 * @Author: luocheng
 * @Date: 2022-04-07 11:07:53
 * @LastEditors: luocheng
 * @LastEditTime: 2022-06-21 16:04:48
-->
<template>
  <div class="static-source">
    <!-- eslint-disable -->
    <section v-for="(item, index) in staticConfig" :key="index">
      <StaticItem :data="item" :index="index" @update="onUpdate" @remove="onRemove"></StaticItem>
    </section>
    <footer class="footer">
      <el-button type="success" @click="onPlus">新增</el-button>
      <el-button type="primary" @click="onSubmit">提交</el-button>
    </footer>
  </div>
</template>

<script>
import { mapState } from 'vuex';
import StaticItem from './StaticItem';

export default {
  name: 'StaticSource',
  components: {
    StaticItem
  },
  data() {
    return {
      staticConfig: [
        {
          objectUUID: '',
          viewUUID: '',
          chartType: 'bar',
          x_fields: [
            {
              uuid: '',
              code: '',
              name: ''
            }
          ],
          y_fields: [
            {
              uuid: '',
              code: '',
              name: ''
            }
          ],
          completionRatio: []
        }
      ]
    }
  },
  computed: {
    ...mapState({
			chartData: (state) => state.chart.chartData || null
		}),
  },
  created() {
    if (this.chartData?.staticConfig) {
      this.staticConfig = this.chartData?.staticConfig;
    }
  },
  methods: {
    /**
     * @desc: 更新配置
     */
    onUpdate({ data, index }) {
      this.staticConfig.splice(index, 1, {
        ...data
      });
    },
    /**
     * @desc: 移除
     * @param {Number} index
     */
    onRemove(index) {
      this.staticConfig.splice(index, 1);
    },
    /**
     * @desc: 提交数据
     */
    onSubmit() {
      this.$store.commit('setChartData', {
        ...this.chartData,
				staticConfig: this.staticConfig
			});
    },
    /**
     * @desc: 新增
     */
    onPlus() {
      this.staticConfig.push({
        objectUUID: '',
        viewUUID: '',
        chartType: 'bar',
        x_fields: [
          {
            uuid: '',
            code: '',
            name: ''
          }
        ],
        y_fields: [
          {
            uuid: '',
            code: '',
            name: ''
          }
        ],
        completionRatio: []
      });
    }
  }
}
</script>

<style lang="less" scoped>
.static-source{
  width: 100%;
  .footer{
    padding-top: 15px;
    .el-button{
      width: 120px;
    }
  }
}
</style>