<!--
 * @Description: 说明问题位置配置
 * @Author: luocheng
 * @Date: 2022-07-26 16:09:40
 * @LastEditors: 吴绍鹏 542278473@qq.com
 * @LastEditTime: 2022-12-06 11:07:32
-->
<template>
  <div class="label-position">
    <header class="label-header">
      <el-button type="primary" @click="onSave">保存</el-button>
    </header>
    <article class="label-content">
      <!-- 动态统计 -->
      <template v-if="sourceType === 'dynamic'">
        <SeriesLabelItem v-for="(item, index) in configData"
          :key="index"
          :config="item"
          :index="index"
          @update="onUpdate"
        ></SeriesLabelItem>
      </template>
    </article>
  </div>
</template>

<script>
import { mapState } from 'vuex';
import SeriesLabelItem from './SeriesLabelItem';

export default {
  name: 'SeriesLabel',
  components: {
    SeriesLabelItem
  },
  data() {
    return {
      configData: []
    }
  },
  computed: {
    ...mapState({
      chartData: state => state.chart.chartData || null,
    }),
    // 图表配置类型
    sourceType() {
      return this.chartData?.sourceType || '';
    }
  },
  watch: {
    sourceType: {
      handler(newVal, oldVal) {
        console.log(newVal, oldVal);
        if (newVal === oldVal) return;
        this.configData = [];
        if (newVal === 'dynamic') {
          // 动态统计
          const { configs = [], specialAttributes } = this.chartData;
          console.log(specialAttributes?.seriesLabel?.length, '----specialAttributes?.seriesLabel.length---')
          if (specialAttributes?.seriesLabel?.length) {
            this.configData = specialAttributes?.seriesLabel;
            return;
          }
          // 初始化
          configs.forEach( ele => {
            console.log(ele);
            this.configData.push({
              chartType: ele.chartType,
              position: 'top',
              distance: 5,
              padding: 0
            })
          });
        }
      },
      immediate: true
    }
  },
  methods: {
    /**
     * @desc: 保存
     */
    onSave() {
      console.log('保存----');
      this.$store.commit('setChartData', {
				...this.chartData,
				specialAttributes: {
          ...this.chartData?.specialAttributes || {},
          seriesLabel: this.configData || []
        }
			});
    },
    /**
     * @desc: 更新配置
     * @param {Number} index 待更新下标
     * @param {Object} config 更新后配置
     */
    onUpdate({ index, config }) {
      this.configData.splice(index, 1, config);
    }
  }
}
</script>

<style lang="less" scoped>
.label-position{
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  .label-header{
    width: 100%;
    display: flex;
    padding-bottom: 10px;
    .el-button{
      flex: 1;
    }
  }
  .label-content{
    flex: 1;
    width: 100%;
    overflow: hidden;
    overflow-y: auto;
  }
}
</style>