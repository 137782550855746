<!--
 * @Description: 字符串
 * @Author: luocheng
 * @Date: 2022-01-11 09:47:22
 * @LastEditors: luocheng
 * @LastEditTime: 2022-01-12 14:07:47
-->
<template>
	<div class="base-source-model base-com" v-if="bindVal !== null">
		<p class="label">{{ field }}</p>
    <!-- <el-input size="mini" v-model="bindVal" :placeholder="'请输入' + field" clearable @input="updateVal"></el-input> -->
	</div>
</template>

<script>
import baseMixin from './base';

export default {
	name: 'BaseSourceModel',
	mixins: [baseMixin]
};
</script>

<style lang="less" scoped>
.base-com{
  display: flex;
  padding-bottom: 10px;
  .label{
    min-width: 80px;
    max-width: 200px;
    line-height: 28px;
    font-size: 14px;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    margin-right: 10px;
  }
}
</style>