<!--
 * @Description: 维度/指标/分组配置
 * @Author: luocheng
 * @Date: 2022-01-06 17:47:05
 * @LastEditors: luocheng
 * @LastEditTime: 2022-04-29 14:12:18
-->
<template>
	<div class="source-item">
		<h3 class="title">{{ title }}</h3>
		<el-form
			:model="configForm"
			label-width="100px"
		>
			<el-form-item label="目标字段">
				<el-select
					v-model="configForm.field_uuid"
					placeholder="请选择字段"
					filterable
					clearable
					size="small"
					@change="_updateConfig"
				>
					<el-option
						v-for="item in fieldList"
						:key="item.uuid"
						:label="item.name"
						:value="item.uuid"
					></el-option>
				</el-select>
			</el-form-item>
			<el-form-item label="别名字段">
				<el-select
					v-model="configForm.show_field_uuid"
					placeholder="请选择字段"
					filterable
					clearable
					allow-create
					size="small"
					@change="_updateConfig"
				>
					<el-option
						v-for="item in fieldList"
						:key="item.uuid"
						:label="item.name"
						:value="item.uuid"
					></el-option>
				</el-select>
			</el-form-item>
			<el-form-item label="自定义别名" v-if="type === 'group'">
				<el-input v-model="configForm.name" placeholder="请输入自定义别名" clearable @input="_updateConfig"></el-input>
			</el-form-item>
			<el-form-item label="统计方法" v-if="type === 'target'">
				<el-select
					v-model="configForm.method"
					placeholder="请选择统计方法"
					clearable
					size="small"
					@change="_updateConfig"
				>
					<el-option
						v-for="item in methodList"
						:key="item.value"
						:label="item.label"
						:value="item.value"
					></el-option>
				</el-select>
			</el-form-item>
			<el-form-item label="轴线补全" v-if="type === 'dimension'">
				<el-select v-model="configForm.need_all" clearable placeholder="请选择日期补全类型" 
					@change="_updateConfig"
				>
					<el-option label="不补全" value="0"></el-option>
					<el-option label="按日期补全" value="date"></el-option>
					<el-option label="按月份补全" value="month"></el-option>
					<el-option label="按年份补全" value="year"></el-option>
					<el-option label="按数字补全" value="number"></el-option>
				</el-select>
			</el-form-item>
		</el-form>
	</div>
</template>

<script>
export default {
	name: 'SourceItem',
	props: {
		// 类型 dimension 维度 group 分组 target 指标
		type: {
			type: String,
			required: true,
			default: ''
		},
		// 字段列表
		fieldList: {
			type: Array,
			required: true,
			default: () => []
		},
		// 基础配置
		config: {
			type: [Object, null],
			required: true,
			default: () => null
		}
	},
	data() {
		return {
			configForm: {
				field_uuid: '', // 目标字段
				show_field_uuid: '', // 用于显示字段不指定时使用目标字段
				method: '', // 计算方式（仅指标）
				name: '', // 自定义别名 仅分组
				need_all: '0'
			},
			// 统计方式列表
			methodList: [
				{
					label: '总数',
					value: 'count'
				},
				{
					label: '求和',
					value: 'sum'
				},
				{
					label: '最大值',
					value: 'max'
				},
				{
					label: '最小值',
					value: 'min'
				},
				{
					label: '平均值',
					value: 'avg'
				}
			]
		};
	},
	computed: {
		title() {
			if (this.type === 'dimension') return '维度(X轴)';
			if (this.type === 'group') return '分组(柱体数量)';
			if (this.type === 'target') return '指标(Y轴)';
			return '';
		}
	},
	created() {
		if (this.config) {
			this.configForm = this.type === 'group' ? {
				...JSON.parse(JSON.stringify(this.config))
			} : JSON.parse(JSON.stringify(this.config));
		}
	},
	methods: {
		/**
		 * @desc: 更新配置
		 */
		_updateConfig() {
			this.$emit('updateAxisConfig', {
				type: this.type,
				config: JSON.parse(JSON.stringify(this.configForm))
			});
		}
	}
};
</script>

<style lang="less" scoped>
.source-item {
	width: 100%;
	border-top: 1px solid #f2f3f5;
	padding-top: 5px;
	.el-form {
		.el-form-item {
			margin-bottom: 5px;
			.el-form-item__label {
				font-size: 12px;
			}
		}
	}
	.title {
		text-align: left;
		font-size: 12px;
		text-indent: 10px;
	}
}
</style>