<!--
 * @Description: 图表基本信息配置
 * @Author: luocheng
 * @Date: 2022-02-11 14:08:12
 * @LastEditors: luocheng
 * @LastEditTime: 2022-04-29 16:29:37
-->
<template>
  <div class="base-info">
    <el-form :model="baseInfo" :inline="true" label-position="left">
      <el-form-item label="名称">
        <el-input v-model="baseInfo.name" placeholder="请输入名称" clearable @input="onEmit"></el-input>
      </el-form-item>
      <el-form-item label="背景颜色">
        <el-color-picker v-model="baseInfo.background_color" @change="onBackground"></el-color-picker>
      </el-form-item>
    </el-form>
  </div>
</template>

<script>
import { ColorPicker } from 'element-ui';
import { mapState } from 'vuex';

export default {
  name: 'BaseInfo',
  components: {
    'el-color-picker': ColorPicker
  },
  data() {
    return {
      baseInfo: {
        name: '',
        background_color: ''
      }
    }
  },
  computed: {
    ...mapState({
			chartData: (state) => state.chart.chartData || null,
		}),
  },
  created() {
    this.baseInfo = {
      ...JSON.parse(sessionStorage.getItem('canvasStyle'))
    }
  },
  methods: {
    onBackground() {
      this.$store.state.canvasStyle = {
        ...this.$store.state.canvasStyle,
        backgroundColor: this.baseInfo.background_color
      }
    },
    /**
     * @desc: 提交数据
     */
    onEmit() {
      sessionStorage.setItem('canvasStyle', JSON.stringify(this.baseInfo));
    }
  }
}
</script>

<style lang="less" scoped>
.base-info{
  width: 100%;
}
</style>