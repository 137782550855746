<!--
 * @Description: 值绑定
 * @Author: luocheng
 * @Date: 2022-06-22 15:23:28
 * @LastEditors: luocheng
 * @LastEditTime: 2022-06-22 15:48:37
-->
<template>
  <div class="value-item">
    <p class="label">{{ configForm.name }}</p>
    <el-select v-model="configForm.fieldUUID" placeholder="请选择绑定字段" @change="onEmit">
      <el-option v-for="(key, val) in fieldList"
        :key="key"
        :value="val"
        :label="key"
      ></el-option>
    </el-select>
  </div>
</template>

<script>
export default {
  name: 'ValueItem',
  props: {
    config: {
      type: Object,
      required: true,
      default: () => {}
    },
    index: {
      type: Number,
      required: true,
      default: 0
    },
    fieldList: {
      type: Object,
      required: true,
      default: () => {}
    }
  },
  data() {
    return {
      configForm: {
        name: '',
        fieldUUID: '', // 绑定字段
        code: ''
      }
    }
  },
  created() {
    this.configForm = {
      ...this.config
    };
  },
  methods: {
    onEmit() {
      this.$emit('update', {
        config: this.configForm,
        index: this.index
      });
    }
  }
}
</script>

<style lang="less" scoped>
.value-item{
  width: 100%;
  display: flex;
  padding: 3px 0;
  .label{
    width: 100px;
    line-height: 32px;
  }
  .el-select{
    flex: 1;
  }
}
</style>