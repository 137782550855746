<!--
 * @Description: 数据分析组装页面
 * @Author: luocheng
 * @Date: 2021-12-17 15:51:47
 * @LastEditors: 朱廷果 1028509503@qq.com
 * @LastEditTime: 2023-01-06 17:34:42
-->
<template>
	<div
		class="config-chart config-chart-container"
		v-loading="loading"
	>
		<ChartToolbar></ChartToolbar>
		<article
			class="chart-content"
			v-if="chartData"
		>
			<article class="editor-box">
				<ChartEditor></ChartEditor>
			</article>
			<section class="right-side side-bar">
				<ChartConfig></ChartConfig>
			</section>
		</article>
	</div>
</template>

<script>
import ChartToolbar from '@/chart-components/Toolbar';
import ChartEditor from '@/chart-components/editor/ChartEditor';
import ChartConfig from '@/chart-components/config/Index';
import { pageFromDetail } from '@/api/saas'
import { isJSONStr } from '@/utils/tools';
import { mapState } from 'vuex';
import { defaultConfig } from '@/chart-components/config.js';

export default {
	name: 'ConfigChart',
	components: {
		ChartToolbar,
		ChartEditor,
		ChartConfig
	},
	data() {
		return {
			loading: true,
			defaultConfig
		};
	},
	created() {
		this.getComponentData();
	},
	computed: {
		...mapState({
			chartData: (state) => state.chart.chartData || null
		})
	},
	methods: {
		/**
		 * @desc: 获取编辑数据
		 */
		getComponentData() {
			const { pageUUID } = this.$route.query;
			if (!pageUUID) {
				this.closeEditor();
				return;
			}
			// 获取
			this.loading = true;
			pageFromDetail({
				__method_name__: 'dataList',
				page_uuid: pageUUID,
				object_uuid: 'a4f016d6-c602-4492-8874-f088c3c0b3b9',
				view_uuid: 'view61b951c6a8186',
				transcode: 0
			})
				.then((res) => {
					if (!res || res.status !== 200) {
						this.loading = false;
						this.closeEditor();
						return;
					}
					const { page_data } = res.data.data?.[0] || {};
					if (!page_data) {
						this.closeEditor();
						return;
					}
					const { chartData } = isJSONStr(page_data) ? JSON.parse(page_data) : page_data;
					sessionStorage.setItem('canvasStyle', JSON.stringify(res.data.data[0] || {}))
					this.$store.state.canvasStyle = {
						...this.$store.state.canvasStyle,
						backgroundColor: res.data.data[0]?.background_color
					}
					this.$store.commit('setChartData', chartData || this.defaultConfig);
					this.loading = false;
				})
				.catch((err) => {
					console.log(err);
					this.loading = false;
					this.closeEditor();
				});
		},
		closeEditor() {
			this.$router.replace({
				name: 'Index'
			});
		}
	}
};
</script>

<style lang="less" scoped>
.config-chart {
	height: 100%;
	width: 100%;
	display: flex;
	flex-direction: column;
	overflow: hidden;
	.chart-content {
		flex: 1;
		width: 100%;
		overflow: hidden;
		display: flex;
		flex-direction: row;
		.side-bar {
			width: 200px;
		}
		.right-side {
			width: 300px;
			height: 100%;
			border-left: 1px solid #f2f3f5;
			box-sizing: border-box;
			padding: 0 10px;
		}
		.editor-box {
			flex: 1;
			height: 100%;
			box-sizing: border-box;
			padding: 15px;
			background: #f5f5f5;
			overflow: auto;
			position: relative;
		}
	}
}
</style>