<!--
 * @Description: 工具栏
 * @Author: luocheng
 * @Date: 2022-01-04 16:33:07
 * @LastEditors: 朱廷果
 * @LastEditTime: 2022-12-23 11:48:44
-->
<template>
	<div class="analysis-toolbar">
		<div class="actions">
			<el-button
				type="text"
				class="back"
				icon="el-icon-arrow-left"
				@click="onBack"
			>返回</el-button>
			<el-button type="danger" @click="onClear">清空</el-button>
			<!-- <el-button type="primary">预览</el-button> -->
			<el-button type="primary" @click="onSave(false)">保存</el-button>
			<el-button type="success" @click="onSave(true)">保存并刷新</el-button>
			<el-button type="success" @click="onRefresh">刷新图表</el-button>
			<el-divider direction="vertical"></el-divider>
		</div>
		<section class="action-box size">
			<span>画布大小</span>
			<input
				type="number"
				v-model="canvasStyle.width"
			/>
			<span>*</span>
			<input
				type="number"
				v-model="canvasStyle.height"
			/>
		</section>
		<el-divider
			direction="vertical"
			class="space-divider"
		></el-divider>
		<section class="action-box scale">
			<span>画布比例</span>
			<input
				type="number"
				v-model="canvasStyle.scale"
			/>
			<span>%</span>
		</section>
		<MockArchi></MockArchi>
	</div>
</template>

<script>
/* eslint-disable */
import MockArchi from '@/components/layout/MockArchi';
import { Divider } from 'element-ui';
import { mapState } from 'vuex';
import { dataInterface } from '../apis/data';
import eventBus from '@/plugins/eventBus';
import { defaultConfig } from '@/chart-components/config.js';

export default {
	name: 'AnalysisToolbar',
	components: {
		'el-divider': Divider,
		MockArchi
	},
	data() {
		return {
			// 创建图表
			showCreateChart: true
		};
	},
	computed: {
		...mapState([
			'canvasStyle' // height: 880 width: 1895
		])
	},
	methods: {
		/**
		 * @desc: 保存
		 */
		onSave(needFresh) {
			this.$loading();
			const data_id = this.$route.query.id;
			const chartData = this.$store.state.chart.chartData;
			const config = {
				chartData
			}
			dataInterface({
				__method_name__: data_id ? 'updateData' : 'createData',
				data_id: data_id || '',
				object_uuid: 'a4f016d6-c602-4492-8874-f088c3c0b3b9',
				'f708a9c6-2514-47ad-9056-3c3a1c37496f': JSON.stringify(config), // 配置数据
				'd1f7e149-2af7-468e-85be-5ef9bd97f634': JSON.parse(sessionStorage.getItem('canvasStyle'))?.name, // 页面名称
				'3402cf7d-3b26-4d3c-82cb-72f37a7cbcb0': data_id,
				background_color:  this.canvasStyle.backgroundColor
			})
				.then((res) => {
					this.$loading().close();
					if (res.status === 200) {
						this.$message.success('保存成功!');
						if (!data_id) {
							// 新增保存后返回避免id缓存问题
							this.$router.replace({
								path: '/index'
							});
						} else if(needFresh) {
							window.location.reload();
						}
					}
				})
				.catch((err) => {
					this.$loading().close();
					this.$message.error(`保存失败:${err.msg}`);
				});
		},
		/**
		 * @desc: 验证配置项
		 */
		validConfig() {
			if (!this.$store.state.chart.chartData) return false;
			const { type, configs } = this.$store.state.chart.chartData;
			if (!type) {
				this.$message.error('请选择图表类型!');
				return false;
			}
			// 名称移除
			// if (!name) {
			// 	this.$message.error('请设置图表名称!');
			// 	return false;
			// }
			if (!configs || !Array.isArray(configs) || !configs.length) {
				this.$message.error('请至少进行一项配置！');
				return false;
			}
			for (let i = 0; i < configs.length; i++) {
				const { view_uuid, x, y } = configs[i];
				if (!view_uuid) {
					this.$message.error('请选择统计视图！');
					return false;
				}
				if (!x || !x.field_uuid) {
					this.$message.error('请配置维度字段!');
					return false;
				}
				if (!y) {
					this.$message.error('请配置指标字段!');
					return false;
				}
			}
			return true;
		},
		/**
		 * @desc: 清空
		 */
		onClear() {
			this.$confirm('是否确认清空?', '提示').then(() => {
				this.$store.commit('setChartData', defaultConfig);
				this.onRefresh();
			})
		},
		/**
		 * @desc: 刷新图表
		 */
		onRefresh() {
			eventBus.$emit('refreshChart');
		},
		/**
		 * @desc: 返回
		 */
		onBack() {
			this.$confirm('是否放弃编辑内容并返回？', '提示', {
				type: 'warning'
			})
				.then(() => {
          this.$router.go(-1)
          return
					this.$store.commit('setChartData', null);
					this.$router.replace({
						name: 'Index'
					});
				})
				.catch(() => {});
		}
	}
};
</script>

<style lang="less" scoped>
.analysis-toolbar {
	width: 100%;
	min-height: 64px;
	display: flex;
	flex-wrap: wrap;
	box-sizing: border-box;
	padding: 16px;
	border-bottom: 1px solid #f2f3f5;
	display: flex;
	.action-box {
		display: flex;
		span {
			line-height: 32px;
			font-size: 14px;
			padding: 0 10px;
			&:first-of-type {
				padding-left: 0;
			}
		}
		input {
			width: 50px;
			outline: none;
			padding: 0 5px;
			height: 20px;
			margin-top: 5px;
			border: 1px solid #ddd;
			color: #606266;
			border-radius: 2px;
		}
	}
	:deep(.space-divider) {
		margin-top: 10px;
	}
}
</style>
