/*
 * @Description: 饼图配置
 * @Author: luocheng
 * @Date: 2022-01-12 16:06:41
 * @LastEditors: 朱廷果 1028509503@qq.com
 * @LastEditTime: 2023-01-12 10:52:18
 */
import baseTrans from './baseTrans';

export default {
  ...baseTrans,
  showLabel: {
    type: 'Switch',
    name: '显示说明'
  },
  labelColor: {
    name: '标签颜色',
    type: 'Color'
  },
  labelSize: {
    name: '标签字号',
    type: 'Number'
  },
  colorRange: {
    type: 'ColorRange',
    name: '饼图颜色'
  },
  changeToRing: {
    type: 'Switch',
    name: '设为环图'
  },
  startRadius: {
    type: 'Number',
    name: '环起始（%）'
  },
  endRadius: {
    type: 'Number',
    name: '环截止（%）'
  },
  isRose: {
    type: 'Switch',
    name: '南丁格尔图'
  },
  pieLeft: {
    type: 'String',
    name: '饼图左偏移'
  },
  pieTop: {
    type: 'String',
    name: '饼图上偏移'
  },
  borderWidth:{
    type: 'Number',
    name: '间距',
  },
  borderRadius: {
    type: 'Number',
    name: '间距圆角',
  }, 
  borderColor:{
    type: 'Color',
    name: ' 间距颜色'
  }
}