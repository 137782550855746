<!--
 * @Description: 翻牌器配置
 * @Author: luocheng
 * @Date: 2022-01-12 17:48:13
 * @LastEditors: luocheng
 * @LastEditTime: 2022-08-17 14:05:33
-->
<template>
	<div class="config-flop">
		<el-form
			:model="configForm"
			v-if="configForm"
			:inline="true"
			label-width="90px"
		>
			<el-form-item label="code">
				<el-input
					v-model="configForm.key"
					placeholder="请输入key"
					clearable
					size="small"
					@input="_updateConfig"
				></el-input>
			</el-form-item>
				<el-form-item label="标题">
				<el-input
					v-model="configForm.label"
					placeholder="请输入标题"
					clearable
					size="small"
					@input="_updateConfig"
				></el-input>
			</el-form-item>
			<el-form-item label="视图">
				<el-select
					v-model="configForm.view_uuid"
					placeholder="请选择视图"
					filterable
					clearable
					size="small"
					@change="onGetView"
				>
					<el-option
						v-for="view in viewList"
						:key="view.uuid"
						:label="'【' + view.object_name + '】_ '+ view.name"
						:value="view.uuid"
					></el-option>
				</el-select>
			</el-form-item>
			<el-form-item label="统计字段">
				<el-select
					size="small"
					v-model="configForm.field_uuid"
					placeholder="请选择统计字段"
					clearable
					@change="_updateConfig"
				>
					<el-option
						v-for="item in fieldList"
						:key="item.uuid"
						:label="item.name"
						:value="item.uuid"
					></el-option>
				</el-select>
			</el-form-item>
			<el-form-item label="统计函数">
				<el-select
					v-model="configForm.aggregate"
					placeholder="请选择统计函数"
					clearable
					size="small"
					@change="_updateConfig"
				>
					<el-option
						v-for="item in aggList"
						:key="item.value"
						:label="item.label"
						:value="item.value"
					></el-option>
				</el-select>
			</el-form-item>
			<el-form-item label="是否为货币">
				<el-switch v-model="configForm.isMoney" @change="_updateConfig"></el-switch>
			</el-form-item>
			<el-form-item label="单位">
				<el-input v-model="configForm.unit" placeholder="请输入单位" clearable @input="_updateConfig"></el-input>
			</el-form-item>
		</el-form>
	</div>
</template>

<script>
import { getFields } from '@/apis/data';

export default {
	name: 'ConfigFlop',
	props: {
		index: {
			type: Number,
			required: true,
			default: 0
		},
		config: {
			type: Object,
			required: true,
			default: () => {}
		},
		viewList: {
			type: Array,
			required: true,
			default: () => []
		}
	},
	data() {
		return {
			// key: '',
			// label: '',
			// view_uuid: '',
			// field_uuid: '',
			// aggregate: 4
      // unit: ''
			configForm: null,
			// 全局函数列表(可能会补充)
			aggList: [
				{
					label: '最大值',
					value: 0
				},
				{
					label: '最小值',
					value: 1
				},
				{
					label: '平均值',
					value: 2
				},
				{
					label: '求和',
					value: 3
				},
				{
					label: '总数',
					value: 4
				}
			],
			// 字段ID
			fieldList: []
		};
	},
	created() {
		if (this.config) {
			this.configForm = {
				...JSON.parse(JSON.stringify(this.config))
			};
		}
	},
	methods: {
		/**
		 * @desc: 选择了视图
		 */
		onGetView() {
			this._getFields(this.configForm.view_uuid);
			this._updateConfig();
		},
		/**
		 * @desc: 获取字段列表
		 * @param {String} viewUUID 视图UUID
		 */
		_getFields(viewUUID) {
			if (!viewUUID) return;
			const viewObj = this.viewList.find((ele) => ele.uuid === viewUUID);
			if (!viewObj) {
				this.$message.error('不合法的视图！');
				return;
			}
			let object_uuid = '';
			if (viewObj) {
				object_uuid = viewObj.object_uuid;
			}
			getFields({
				object_uuid,
				view_uuid: viewUUID,
				size: 1000,
				page: 1
			})
				.then((res) => {
					this.fieldList = res?.data?.data?.data || [];
					this._updateConfig();
				})
				.catch((err) => {
					this.$message.error('获取视图详情失败！');
					console.log(err);
				});
		},
		/**
		 * @desc: 更新配置
		 */
		_updateConfig() {
			this.$emit('updateConfig', {
				index: this.index,
				config: JSON.parse(JSON.stringify(this.configForm))
			});
		}
	}
};
</script>

<style lang="less" scoped>
.config-flop {
	:deep(.el-form) {
		.el-form-item {
			display: flex;
		}
	}
}
</style>