<!--
 * @Description: 图表属性配置
 * @Author: luocheng
 * @Date: 2022-01-10 16:00:02
 * @LastEditors: luocheng
 * @LastEditTime: 2022-01-12 17:26:37
-->
<template>
	<div class="chart-attr">
		<el-collapse
			v-model="activeName"
			accordion
		>
			<el-collapse-item
				title="基本属性"
				name="base"
			>
				<BaseTemplate></BaseTemplate>
			</el-collapse-item>
		</el-collapse>
	</div>
</template>

<script>
import BaseTemplate from './BaseTemplate';

export default {
	name: 'ChartAttributes',
	components: {
		BaseTemplate
	},
	data() {
		return {
			activeName: 'base'
		};
	}
};
</script>

<style lang="less" scoped>
.chart-attr {
	height: 100%;
	width: 100%;
	overflow-y: auto;
}
</style>