<!--
 * @Description: 配置数据源item
 * @Author: luocheng
 * @Date: 2022-01-07 10:27:22
 * @LastEditors: 朱廷果
 * @LastEditTime: 2022-12-23 09:53:07
-->
<template>
	<div
		class="config-item"
		v-if="configItem"
	>
		<h3 class="item-title">
			<span class="index">{{ index + 1 }} {{ viewName }}</span>
			<el-button
				type="text"
				style="color: #409EFF"
				@click="onToggle"
				v-if="configItem.view_uuid"
			>{{ toggleShow ? '收起' : '展开' }}</el-button>
			<el-button
				type="text"
				style="color: #F56C6C"
				@click="onRemove"
			>移除</el-button>
		</h3>
		<el-form
			:model="configItem"
			size="mini"
		>
			<!-- 翻牌器 -->
			<template v-if="chartType === 'flop'">
				<ConfigFlop
					:config="configItem"
					:index="index"
					:viewList="viewList"
					@updateConfig="_updateFlopConfig"
				></ConfigFlop>
			</template>
			<template v-else>
				<!-- 常规图表 -->
				<el-form-item label="视图">
					<el-select
						v-model="configItem.view_uuid"
						placeholder="请选择视图"
						filterable
						clearable
						@change="_getFields"
						size="mini"
					>
						<el-option
							v-for="item in viewList.filter(ele => ele.name.toString().includes('统计'))"
							:key="item.path"
							:label="'【' + item.object_name + '】_ '+ item.name"
							:value="item.path"
						></el-option>
					</el-select>
				</el-form-item>
				<!-- 折柱图 -->
				<el-form-item label="类型" v-if="chartType === 'barLine'">
					<el-select
						v-model="configItem.chartType"
						placeholder="请选择图表展示类型"
						filterable
						clearable
						size="mini"
						@change="_updateConfig"
					>
						<el-option label="柱状图" value="bar"></el-option>
						<el-option label="折线图" value="line"></el-option>
					</el-select>
				</el-form-item>
				<!-- 维度指标及分组 -->
				<template v-if="configItem.view_uuid && fieldList">
					<div v-show="toggleShow">
						<SourceItem
							:type="'dimension'"
							:config="configItem.x"
							:fieldList="fieldList"
							@updateAxisConfig="_updateAxisConfig"
						></SourceItem>
						<SourceItem
							:type="'target'"
							:config="configItem.y"
							:fieldList="fieldList"
							@updateAxisConfig="_updateAxisConfig"
						></SourceItem>
						<SourceItem
							:type="'group'"
							:config="configItem.group"
							:fieldList="fieldList"
							@updateAxisConfig="_updateAxisConfig"
						></SourceItem>
					</div>
				</template>
			</template>
		</el-form>
	</div>
</template>

<script>
import SourceItem from '../SourceItem.vue';
import { getFields } from '@/apis/data';
import { mapState } from 'vuex';
import ConfigFlop from './ConfigFlop';

export default {
	name: 'ConfigItem',
	props: {
		viewList: {
			// 视图列表
			type: Array,
			required: true,
			default: () => []
		},
		config: {
			type: Object,
			required: true,
			default: () => {}
		},
		index: {
			type: Number,
			required: true,
			default: 0
		}
	},
	components: {
		SourceItem,
		ConfigFlop
	},
	data() {
		return {
			fieldList: [], // 字段列表
			configItem: null,
			// 收起展开
			toggleShow: false,
			// 视图名称
			viewName: ''
		};
	},
	computed: {
		...mapState({
			chartType: state => state.chart.chartData?.type || ''
		})
	},
	created() {
		this.configItem = {
			...this.config
		};
		this._getFields(this.configItem.view_uuid, true);
	},
	methods: {
		/**
		 * @desc: 展开收起
		 */
		onToggle() {
			this.toggleShow = !this.toggleShow;
		},
		/**
		 * @desc: 移除配置
		 */
		onRemove() {
			this.$confirm(`是否确认移除【${this.viewName}】配置？`, '提示')
				.then(() => {
					this.$emit('removeItem', this.index);
				})
				.catch(() => {});
		},
		/**
		 * @desc: 更新配置项
		 */
		_updateConfig() {
			this.$emit('updateConfig', {
				index: this.index,
				config: JSON.parse(JSON.stringify(this.configItem))
			});
		},
		/**
		 * @desc: 更新子配置项
		 * @param {String} type 类型
		 * @param {Object} config 配置
		 */
		_updateAxisConfig({ type, config }) {
			if (!type || !config) return false;
			const TYPEOBJ = {
				dimension: 'x',
				target: 'y',
				group: 'group'
			};
			this.$set(this.configItem, TYPEOBJ[type], {
				...config
			});
			this._updateConfig();
		},
		/**
		 * @desc: 获取字段列表
		 * @param {String} viewUUID 视图UUID
		 */
		_getFields(viewUUID, isInit) {
			if (!viewUUID) return;
			const viewObj = this.viewList.find((ele) => ele.path === viewUUID);
			if (!viewObj && !isInit) {
				this.$message.error('不合法的视图！');
				return;
			}
      let object_uuid = '';
			if (viewObj) {
				const { object_name, name } = viewObj;
				this.viewName = `【 ${object_name}】_ ${name}`;
				object_uuid = viewObj.object_uuid;
			}
			getFields({
				object_uuid,
				view_uuid: viewUUID,
				size: 1000,
				page: 1
			})
				.then((res) => {
					this.fieldList = res?.data?.data?.data || [];
					this._updateConfig();
				})
				.catch((err) => {
					this.$message.error('获取视图详情失败！');
					console.log(err);
				});
		},
		/**
		 * @desc: 更新
		 * @param {Number} index 下标
		 * @param {Object} config 配置对象
		 * @return {*}
   	*/
		_updateFlopConfig({ index, config }) {
			this.$emit('updateConfig', {
				index,
				config: JSON.parse(JSON.stringify(config))
			});
		}
	}
};
</script>

<style lang="less" scoped>
.config-item {
	margin-top: 10px;
	border-radius: 4px;
	width: 100%;
	border: 1px solid #f2f3f5;
	box-sizing: border-box;
	padding: 10px 10px 0 10px;
	box-shadow: 2px 2px 8px 2px rgba(0, 0, 0, 0.1);
	.item-title {
		display: flex;
		padding-bottom: 5px;
		.index {
			flex: 1;
			text-align: left;
			box-sizing: border-box;
			padding-right: 10px;
			overflow: hidden;
			text-overflow: ellipsis;
			white-space: nowrap;
			font-size: 14px;
			line-height: 32px;
		}
	}
	.el-form {
		.el-form-item {
			margin-bottom: 5px;
		}
	}
	&:last-of-type {
		margin-bottom: 10px;
	}
}
</style>