<!--
 * @Description: 选择字段
 * @Author: luocheng
 * @Date: 2022-04-07 14:04:16
 * @LastEditors: luocheng
 * @LastEditTime: 2022-05-07 16:11:03
-->
<template>
  <el-select v-model="result" filterable allow-create clearable placeholder="请选择" @change="onSelect">
    <el-option v-for="field in options" :key="field.id"
      :label="field[labelField]"
      :value="field[valueField]"
    ></el-option>
  </el-select>
</template>

<script>
export default {
  name: 'SelectCode',
  props: {
    value: {
      required: true,
      default: ''
    },
    options: {
      type: Array,
      required: true,
      default: () => []
    },
    // 取值字段
    valueField: {
      type: String,
      required: false,
      default: 'uuid'
    },
    // 说明
    labelField: {
      type: String,
      required: false,
      default: 'name'
    }
  },
  data() {
    return {
      result: ''
    }
  },
  created() {
    this.result = this.value || '';
  },
  methods: {
    onSelect() {
      this.$emit('input', this.result);
    }
  }
}
</script>

<style lang="less" scoped>
.select-code{}
</style>