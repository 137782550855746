<!--
 * @Description: 数据源配置
 * @Author: luocheng
 * @Date: 2022-04-07 11:02:05
 * @LastEditors: luocheng
 * @LastEditTime: 2022-06-22 10:32:14
-->
<template>
  <div class="source-config">
    <el-radio-group v-model="sourceType" @change="onChangeType">
      <el-radio-button :label="'dynamic'">动态统计</el-radio-button>
      <el-radio-button :label="'static'">静态统计</el-radio-button>
      <el-radio-button :label="'custom'">自定义统计</el-radio-button>
      <el-radio-button :label="'fillData'">填充数据</el-radio-button>
      <!-- <el-radio-button :label="'interface'">接口统计</el-radio-button> -->
    </el-radio-group>
    <!-- 动态统计 -->
    <DynamicSource v-if="sourceType === 'dynamic'"></DynamicSource>
    <!-- 静态统计 -->
    <StaticSource v-else-if="sourceType === 'static'"></StaticSource>
    <!-- 自定义统计 -->
    <CustomSource v-else-if="sourceType === 'custom'"></CustomSource>
    <!-- 接口统计 -->
    <InterfaceSource v-else-if="sourceType === 'interface'"></InterfaceSource>
    <FillData v-else-if="sourceType === 'fillData'"/>
  </div>
</template>

<script>
import { RadioButton, RadioGroup } from 'element-ui';
import DynamicSource from './DynamicSource';
import StaticSource from './StaticSource';
import CustomSource from './customSource/Index';
import FillData from './fill_data';
import { mapState } from 'vuex';
import InterfaceSource from './InterfaceSource';

export default {
  name: 'SourceConfig',
  components: {
    'el-radio-group': RadioGroup,
    'el-radio-button': RadioButton,
    DynamicSource,
    StaticSource,
    InterfaceSource,
    CustomSource,
    FillData
  },
  data() {
    return {
      sourceType: 'dynamic'
    }
  },
  computed: {
		...mapState({
			chartData: (state) => state.chart.chartData || null
		}),
		// 图表配型
		chartType() {
			return this.chartData?.type;
		}
	},
  created() {
    this.sourceType = this.chartData.sourceType || 'dynamic';
  },
  methods: {
    /**
     * @desc: 改变类型
     */
    onChangeType() {
      this.$store.commit('setChartData', {
        ...this.chartData,
        sourceType: this.sourceType
			});
    }
  }
}
</script>

<style lang="less" scoped>
.source-config{
  width: 100%;
  .el-radio-group{
    display: flex;
    flex-wrap: wrap;
    .el-radio {
      margin-bottom: 10px;
    }
  }
}
</style>