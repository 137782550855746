<!--
 * @Description: 数据绑定配置
 * @Author: luocheng
 * @Date: 2022-01-05 15:30:50
 * @LastEditors: 朱廷果 1028509503@qq.com
 * @LastEditTime: 2022-12-22 11:23:32
-->
<template>
	<div class="data-source" v-if="dataSource">
		<el-form :model="dataSource" label-width="100px">
			<!-- <el-form-item label="name">
				<el-input
					v-model="dataSource.name"
					placeholder="请输入图表名称"
					clearable
				></el-input>
			</el-form-item> -->
			<div class="config-item-box" v-for="(item, index) in dataSource.configs" :key="index">
				<ConfigItem
					:config="item"
					:index="index"
					@updateConfig="_updateConfig"
					:viewList="viewList"
					@removeItem="onRemoveItem"
				></ConfigItem>
			</div>
			<el-form-item>
				<el-button
					type="primary"
					@click="onSubmit"
				>确认</el-button>
				<el-button
					type=""
					@click="onPlus"
				>新增</el-button>
			</el-form-item>
		</el-form>
	</div>
</template>

<script>
/* eslint-disable */
// import { dataInterface } from '@/apis/data';
import { etlList } from '@/api/myApps.js' 
import { mapState } from 'vuex';
import ConfigItem from './ConfigItem';

export default {
	name: 'DataSource',
	components: {
		ConfigItem
	},
	data() {
		return {
			viewList: [], // 视图列表
			dataSource: null
		};
	},
	computed: {
		...mapState({
			chartData: (state) => state.chart.chartData || null
		}),
		// 图表配型
		chartType() {
			return this.chartData?.type;
		}
	},
	watch: {
		chartType() {
			this.dataSource = null;
			setTimeout(() => {
				this.dataSource = this.chartData;
			}, 0);
		}
	},
	created() {
		this.dataSource = this.chartData;
		this._getViewList();
	},
	methods: {
		/**
		 * @desc: 提交配置
		 */
		onSubmit() {
			this.$store.commit('setChartData', {
				...this.dataSource,
				// 避免覆盖
				type: this.chartData.type,
				component: this.chartData.component,
				id: this.chartData?.id
			});
		},
		/**
		 * @desc: 新增一组视图
		 */
		onPlus() {
			this.dataSource.configs.push(
				this.chartType === 'flop'
					? {
							key: '',
							label: '',
							view_uuid: '',
							field_uuid: '',
							aggregate: 4,
							isMoney: false,
							unit: ''
					  }
					: {
							view_uuid: '',
							chartType: '',
							x: {
								field_uuid: '',
								show_field_uuid: ''
							},
							y: {
								field_uuid: '',
								show_field_uuid: '',
								method: 'count'
							},
							group: {
								field_uuid: '',
								show_field_uuid: ''
							}
					  }
			);
		},
		/**
		 * @desc: 移除数据源配置
		 * @param {Number} index 下标
		 */
		onRemoveItem(index) {
			if (index < this.dataSource.configs.length && index >= 0) {
				this.dataSource.configs.splice(index, 1);
			}
		},
		/**
		 * @desc: 获取视图列表
		 */
		_getViewList() {
			etlList()
				.then((res) => {
          console.log(res)
					if (res.data.data) {
						this.viewList = res.data.data || [];
					}
				})
				.catch((err) => {
					console.log(err);
					this.$message.error('获取视图列表失败！');
				});
		},
		/**
		 * @desc: 更新配置
		 * @param {Number} index 下标
		 * @param {Object} config 配置
		 */
		_updateConfig({ index, config }) {
			if (index < this.dataSource.configs.length && index >= 0 && config) {
				this.dataSource.configs.splice(index, 1, {
					...config
				});
			}
		}
	}
};
</script>

<style lang="less" scoped>
.data-source {
	width: 100%;
	.config-item-box {
		padding: 0 10px;
	}
}
</style>
