/*
 * @Description: 混入
 * @Author: luocheng
 * @Date: 2022-01-07 09:33:53
 * @LastEditors: luocheng
 * @LastEditTime: 2022-01-12 14:20:34
 */

export default {
  props: {
    // 字段
    field: {
      type: String,
      required: true,
      default: ''
    },
    // 绑定值
    value: {
      type: [String, Number, Boolean, Object, Array],
      default: () => ''
    }
  },
  data() {
    return {
      bindVal: null
    }
  },
  created() {
    this.bindVal = this.value;
  },
  methods: {
    updateVal() {
      this.$emit('input', this.bindVal);
    }
  }
}