<!--
 * @Description: 配置
 * @Author: luocheng
 * @Date: 2022-01-10 15:32:35
 * @LastEditors: luocheng
 * @LastEditTime: 2022-07-26 16:00:03
-->
<template>
	<div class="chart-config">
		<el-tabs v-model="activeName">
			<el-tab-pane
				label="基本配置"
				name="base"
			>
				<BaseConfig></BaseConfig>
			</el-tab-pane>
			<el-tab-pane
				label="图表属性"
				name="chartAttr"
				v-if="chartData && chartData.type"
			>
				<ChartAttributes></ChartAttributes>
			</el-tab-pane>
			<el-tab-pane
				label="特殊配置"
				name="special"
			>
				<SpecialConfig></SpecialConfig>
			</el-tab-pane>
		</el-tabs>
	</div>
</template>

<script>
import { TabPane, Tabs } from 'element-ui';
import BaseConfig from './baseConfig/Index';
import ChartAttributes from './chartAttributes/Index.vue';
import { mapState } from 'vuex';
import SpecialConfig from './specialConfig/Index';

export default {
	name: 'ChartConfig',
	components: {
		'el-tabs': Tabs,
		'el-tab-pane': TabPane,
		BaseConfig,
		ChartAttributes,
		SpecialConfig
	},
	data() {
		return {
			activeName: 'base'
		};
	},
	computed: {
		...mapState({
      chartData: state => state.chart.chartData || null,
    })
	}
};
</script>

<style lang="less" scoped>
.chart-config {
	height: 100%;
	width: 100%;
	overflow: hidden;
	box-sizing: border-box;
	padding-bottom: 15px;
	:deep(.el-tabs.el-tabs--top){
		height: 100%;
		width: 100%;
		overflow: hidden;
		display: flex;
		flex-direction: column;
		.el-tabs__header {
			margin-bottom: 0;
		}
		.el-tabs__content {
			flex: 1;
			width: 100%;
			overflow: hidden;
			overflow-y: auto;
		}
	}
}
</style>